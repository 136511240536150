import React, { useState ,useEffect, useRef} from 'react';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import { Input } from "antd";
import SearchImg from "../assets/images/search.svg";
import { get, post,getWithHeader } from "../lib/HttpServices";
import axios from "axios";
import { Button, Dropdown, Space ,Menu, Modal, Form, Select, Table } from 'antd';
import DocumentMenuImg from "../assets/images/drpdown-menu.svg";
import DownloadMenuImg from "../assets/images/download.png";
import DocumentImg from "../assets/images/document.svg";
import ViewMenuImg from "../assets/images/view.png";
import { toast } from 'react-toastify';

const Appointments = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const [fiterText, setfiterText] = useState("");
  // 
  const [selectedlisteofdoc,setselectedlisteofdoc] = useState([])
  const [dropdownOpenRow, setDropdownOpenRow] = useState("");
  const [requestedpatientContactNumber,setRequestedpatientContactNumber] = useState("");
  const [requestedpatientName,setRequestedpatientName] = useState("");
  const [selectedDefaultRequestedlisteofdoc,setselectedDefaultRequestedlisteofdoc] = useState([]);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
const [modalOpen, setModalOpen] = useState(false);
const [imageSrc, setImageSrc] = useState(null);
const [fileType, setFileType] = useState("");

const [isModalOpen, setIsModalOpen] = useState(false);
const [patientName,setpatientName] = useState("");
  const [patientContactNumber,setpatientContactNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(15);
  const [isEdit, setIsEdit] = useState(false);
  const [listofdoc,setListsofDoc] = useState([]);
  const[filterCustomerData,setfilterCustomerData] = useState([]);
  
// 
const [isModalVisible, setIsModalVisible] = useState(false);
const [form] = Form.useForm();
const showModal = () => {
  setIsModalVisible(true);
};

const options = listofdoc.map((doc)=>({
    value :doc.id,
    label : doc.title
  
  }));

  const doc_list =  listofdoc.map((doc)=>({
    key :doc.id,
    name : doc.title
  }));


const handleChange = (value) => {
    const validValues = value.filter((val) =>
        options.some((option) => option.value === val)
      );
    
      console.log("Updated selected documents:", validValues);
      setselectedlisteofdoc(validValues); // Update state with only valid values
};


const handleSave = () => {
  form.validateFields().then((values) => {
    console.log("Form Data:", values);
    post(`add_document_names`,values).
    then((res)=>{
   
       toast.success(res.data?.message, {
                  autoClose: 3000,
                });
    
    })

    setIsModalVisible(false);
    form.resetFields();
  }).catch((error) => {
    console.error("Validation Failed:", error);
  });
};





const handleCancel = () => {
  setIsModalVisible(false);
  form.resetFields(); // Reset form when closing the modal
};
// 
const dropdownRef = useRef(null);

const handlePatientName = (e)=>{
    setpatientName(e.target.value)
  }



 const handleRequestDocument = (row)=>{
    setIsModalOpen(true);
    console.log("aaa")
  if (Array.isArray(row.documents)) {
    // Your logic for handling documents

    console.log("row::", row);

  setpatientContactNumber(row.phone_number);
  setpatientName(row.user_name);
  // Ensure requested_documents is defined and is an array before mapping
  const defaultDocuments = Array.isArray(row.requested_documents)
      ? row.requested_documents.map(doc => doc.document_name)
      : [];
  setselectedlisteofdoc(defaultDocuments);
  setIsModalOpen(true);
  console.log("defaultDocuments:-:", defaultDocuments);
  } else {
    console.error("Documents are not available or are not an array");
  }

 }



const toggleDropdownRow = (rowId, event) => {
   const rect = event.target.getBoundingClientRect();
   setDropdownPosition({ top: rect.bottom, left: rect.left });
   setDropdownOpenRow((prev) => (prev === rowId ? null : rowId));
 };

 useEffect(()=>{
    
    get(`get_documents_list`).
    then((res)=>{
      setListsofDoc(res.data.documents)

    }).catch((error) => {
        if (error.response) {
         
        console.log("errr");
        }
      });
  },[])




 const validateInputs = () => {
    const newErrors = {};

    // Validate name
    if (!patientName.trim()) {
      newErrors.patientName = "Name is required.";
    }
    if (!/^[A-Za-z\s]{2,120}$/.test(patientName)) {
        newErrors.patientName = "Name must be between 2 and 120 characters and contain only letters.";
      }
    // Validate phone number
    const phoneRegex = /^[+]?[0-9]{10,15}$/;
    if (!patientContactNumber.trim()) {
      newErrors.patientContactNumber = "Phone number is required.";
    } else if (!phoneRegex.test(patientContactNumber)) {
      newErrors.patientContactNumber = "Phone number must be between 10 and 15 digits, including country code!";
    }

    // Validate document selection
    if (selectedlisteofdoc.length === 0) {
      newErrors.selectedlisteofdoc = "At least one document must be selected.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



 
const handleUserEditOk = () => {
    if (validateInputs()) {
    let payload = {
      user_name: patientName,
      phone_number:patientContactNumber,
      documents:selectedlisteofdoc.map((id)=>({
        document_name: listofdoc.find((doc)=>doc.id === id)?.title || "Unknown",
        status:"requested",
    }))
    }
    console.log("add_user::",payload)

    post(`add_user`,payload).
    then((res)=>{
      console.log(res);
       toast.success(res.data?.message, {
                  autoClose: 3000,
                });
                
    }).catch((error) => console.error("API Error::", error));
    handlUsereditCancel();
  }
  
  };


 const handlUsereditCancel = () => {
    console.log("Cancel ")
    setIsModalOpen(false);
    setpatientName("");
    setpatientContactNumber("");
    setselectedlisteofdoc([]);
    setErrors({});

  };
 const handleRequestCancel= ()=>{
   setIsRequestModalOpen(false)
 }
 useEffect(() => {
  // Handle click outside the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpenRow(null); // Close the dropdown
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);
//  
  // 
  const onChangeFilter = async (e) => {
    // debugger;
    const temp_filter_text = e.target.value
    setfiterText(temp_filter_text)
  
    // Clear the date filter field when typing in the search input
   
    var searchData = leadCustomerData.filter((item) => {
      if (
        // item?.firstName
        //   .toString()
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase())
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      ) {
        return item;
      }
    });
  
    setfilterCustomerData(searchData);
  };

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [leadCustomerData, setleadCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 // 
 const documentMenu = (row) => (
  <Menu>
  {Array.isArray(row.documents) && row.documents.length > 0 ? (
    row.documents.map((doc) => (
      <Menu.Item key={doc.file_id} className="doc-lits">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '180px', marginRight: '10px', background: 'rgb(241, 244, 248)', borderRadius: '50px', padding: '8px 11px' }}>
            <img src={DocumentImg} alt="" style={{ width: '14px', marginRight: '8px' }} />
            {doc.file_name}
          </span>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div style={{
                  background: 'rgb(241, 244, 248)',
                  borderRadius: '50px',
                  // margin: '0px 10px',
                  width: '35px',
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
            }} >
            <img src={ViewMenuImg} alt="View" style={{ width: '20px', cursor: 'pointer', background: 'rgb(241, 244, 248)', borderRadius: '50px' }}
              onClick={() => {
                setModalOpen(true);
                setImageSrc(doc.file_path);
                setFileType(doc.file_type);
              }}
            />
            </div>
            <div style={{
                  background: 'rgb(241, 244, 248)',
                  borderRadius: '50px',
                  // margin: '0px 10px',
                  width: '35px',
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
            }} >
            <img src={DownloadMenuImg} alt="Download" style={{ width: '18px', cursor: 'pointer' }}
              onClick={async () => {
                try {
                  const response = await fetch(doc.file_path);
                  if (!response.ok) throw new Error('Failed to download file');
                  const blob = await response.blob();
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = doc.file_name || 'downloaded_file';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(link.href);
                } catch (error) {
                  console.error('Error downloading file:', error);
                }
              }}
            />
            </div>
          </div>
        </div>
      </Menu.Item>
    ))
  ) : (
    <Menu.Item>No documents available</Menu.Item>
  )}
</Menu>
);

// 
  const columns = [
    {
      name: <div>User Name</div>,
      selector: (row) => `${row.user_name}`,
    },
    // {
    //   name: <div>Last Name</div>,
    //   selector: (row, i) => row.last_name,
    // },
    {
      name: <div>Email</div>,
      selector: (row, i) => row.email,
    },
    {
      name: <div>Phone</div>,
      selector: (row, i) => row.phone_number,
    },
    {
      name: <div>Date</div>,
      selector: (row, i) => row.appointment_date,
    },
    {
      name: <div>Time</div>,
      selector: (row, i) => row.appointment_time,
    },
    {
      name: <div>Addtional Requests</div>,
      selector: (row, i) => row.special_requests,
    },

    {
      name: "Documents",
      selector: (row) => row.documents,
      minWidth: '300px',
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "60%" }}>
          <span style={{ minWidth: "100px" }}>{row.documents?.length || 0} Documents</span>
          <Dropdown overlay={documentMenu(row)} trigger={["click"]} placement="bottomRight">
            <img src={DocumentMenuImg} alt="Menu" style={{ width: "25px", marginLeft: "15px", cursor: "pointer" }} />
          </Dropdown>
          <button className="btn custom-btn-sec" style={{ marginLeft: "15px", minWidth: "125px" }} onClick={() => handleRequestDocument(row)}>
            Request <img src={DocumentImg} alt="document" style={{ width: '12px' }} className="ms-2" />
          </button>
        </div>
      ),
    }
  ];
  const dummyData = [
    {
      first_name: "John",
      last_name: "Doe",
      email: "john.doe@example.com",
      phone_number: "1234567890",
      date: "01-01-2024",
      time: "10:00 AM",
      additional_requests: "None",
    },
    {
      first_name: "Jane",
      last_name: "Smith",
      email: "jane.smith@example.com",
      phone_number: "9876543210",
      date: "08-11-2025",
      time: "02:00 PM",
      additional_requests: "None",
    },
    {
      first_name: "Mike",
      last_name: "Johnson",
      email: "mike.johnson@example.com",
      phone_number: "5555555555",
      date: "05-05-2025",
      time: "01:00 PM",
      additional_requests: "None",
    },
    {
      first_name: "Nikhil",
      last_name: "John",
      email: "nikhil.doe@example.com",
      phone_number: "98765567890",
      date: "01-01-2024",
      time: "10:00 AM",
      additional_requests: "None",
    },
    {
      first_name: "Smith",
      last_name: "Smith",
      email: "smith@example.com",
      phone_number: "9876543210",
      date: "08-11-2025",
      time: "02:00 PM",
      additional_requests: "None",
    },
  ];


  useEffect(() => {
    get(`get_appointments`).then((res) => {
      console.log("res data is", res);
      const temp_appointmant_data = res.data;
      console.log("temp_appointmant_data is", temp_appointmant_data);
      setleadCustomerData([temp_appointmant_data]);
      setfilterCustomerData(temp_appointmant_data)
    });
  }, [dateRange]);



  
    
  const fetchData = () => {
    get(`get_appointments`).then((res) => {
        console.log("res data is", res);
        const temp_appointmant_data = res.data;
        console.log("temp_appointmant_data is", temp_appointmant_data);
        setleadCustomerData(temp_appointmant_data);
      });
  };

     

      const handleRefresh = () => {
        fetchData();
        setTimer(15); // Reset timer on manual refresh
      };


      useEffect(() => {
        // Fetch data initially
        fetchData();
    
        // Set up interval for repeated fetch
        const intervalId = setInterval(() => {
          fetchData();
          setTimer(15); // Reset timer after fetch
        }, 15000);
    
        // Countdown logic
        const countdownInterval = setInterval(() => {
          setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
    
        // Cleanup on unmount
        return () => {
          clearInterval(intervalId);
          clearInterval(countdownInterval);
        };
      }, [dateRange]);




  // useEffect(() => {
  //   if (imageId !== "") {
  //     setIsLoading(true)
  //     getWithHeader(`fetch_image_data/${imageId}`, { responseType: 'blob' }) // Set responseType to 'blob' for binary data
  //       .then((res) => {
  //         const blob = new Blob([res.data], { type: 'image/jpeg' }); // Adjust type based on image format
  //         const imageBlobUrl = URL.createObjectURL(blob);
  //         setImageSrc(imageBlobUrl);
  //       })
  //       .catch((error) => console.error('Error fetching image:', error)).finally(()=>{
  //         setIsLoading(false)
  //       });
  //   }
  // }, [imageId]);

  // useEffect(() => {
  //   return () => {
  //     if (imageSrc) {
  //       URL.revokeObjectURL(imageSrc);
  //     }
  //   };
  // }, [imageSrc]);

  // const handleViewImage = (id) => {
  //   setImageId(id);
  //   setModalOpen(true);
  // };


  const dataSource = [
    {
      key: '1',
      name: 'Consent Form',
    },
    {
      key: '2',
      name: 'ID Proof',
    },
    {
      key: '3',
      name: 'Passport',
    },
   

  ];
  
  const columnsList = [
    {
      title: 'Document List',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />
        <div className="project-task">
          <div className="inside-box p-4 position-relative">
            <div className="d-flex align-items-center justify-content-end mb-4">
              <div className="me-3">
                <button className="btn btn-primary" onClick={showModal}>Create Document Name</button>
              </div>
              {/*  */}
              <Modal
                title="Create Document Name"
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleSave}
                okButtonProps={{ style: { backgroundColor: "rgb(85, 98, 218)", borderColor: "rgb(85, 98, 218)" } }} 
              >
                <Form form={form} layout="vertical">
                  <Form.Item
                    name="title"
                    label="Document Name"
                    rules={[{ required: true, message: "Please enter document name" }]}
                  >
                    <Input placeholder="Enter document name" />
                  </Form.Item>
                </Form>
                <div>
                  {/* <h5>Document List</h5> */}
                  <Table dataSource={doc_list} columns={columnsList} className="doc-list" style={{
                    maxHeight: '250px',
                    overflowX:'hidden',
                    overflowY:'auto'
                  }}
                  
                  pagination={dataSource.length > 5 ? { pageSize: 5 } : false} 
                   />
                </div>
              </Modal>
              {/*  */}
              <form action="">
                <div
                  className="bg-light rounded rounded-pill shadow-sm me-3"
                  style={{ border: "1px solid #DEDEDE", minWidth: "370px" }}
                >
                  <div className="input-group search-space">
                    <Input
                      type="search"
                      placeholder="Search with Name or Date"
                      value={fiterText}
                      onChange={onChangeFilter}
                      aria-describedby="button-addon1"
                      className=" ms-2 form-control rounded-pill border-0 bg-light"
                    />
                    <div className="input-group-append">
                      <button
                        id="button-addon1"
                        type="submit"
                        className="btn btn-link text-primary border-0"
                      >
                        <img src={SearchImg} alt="" className="" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>





            <Modal
  title={
    <div className="modal-headers">
      <span>{"Edit User"}</span>      
      <div className="modal-actions">
      <button className="btn btn-secondary" onClick={handlUsereditCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleUserEditOk}>
          Save
        </button>
      </div>
    </div>
  }
  open={isModalOpen}
  onCancel={handlUsereditCancel}
  closable={false} // Removes the close icon
  footer={null} // Hides default footer buttons
  className="add-patient"
>
  <div className="form-group mt-3">
    <label>Name</label>
    <input
      className="form-control"
      type="text"
      placeholder="Name"
      value={patientName}
      onChange={handlePatientName}
    />
    {errors.patientName && <span className="text-danger">{errors.patientName}</span>}
  </div>
  <div className="form-group mt-3">
    <label>Mobile</label>
    <input
      className="form-control"
      type="text"
      placeholder="Mobile Number"
      value={patientContactNumber}
      disabled={true}
      onChange={(e) => {
        const input = e.target.value;
        if (/^\d*$/.test(input)) {
          setpatientContactNumber(input);
        }
      }}
    />
    {errors.patientContactNumber && (
      <span className="text-danger">{errors.patientContactNumber}</span>
    )}
  </div>
  <div className="mt-3 mb-4">
    <label>List of Documents</label>
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Select documents"
      value={selectedlisteofdoc}
      onChange={handleChange}
      options={options}
      showSearch={true}  // Enables filtering of available options
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
    {errors.selectedlisteofdoc && (
      <span className="text-danger">{errors.selectedlisteofdoc}</span>
    )}
  </div>
</Modal> 
                    


            <DataTable
              columns={columns}
              data={filterCustomerData}
            //   data={dummyData}
              pagination
              className="table-style"
            />

<div
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      display: "flex",
                      alignItems: "center",
                      left:"25px"
                    }}
                  >
                    <button className="btn btn-primary" onClick={handleRefresh}>
                      Refresh
                    </button>
                    <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                      Next auto-refresh in: {timer}s
                    </span>
                  </div>


          </div>
        </div>
      </section>


      
      <Modal
        title="Uploaded Document d"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {fileType !== "pdf" ? (
          imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              style={{ width: "100%", height: "500px", objectFit: "contain" }}
            />
          ) : (
            <p>No image to show</p>
          )
        ) : (
          <iframe
            src={imageSrc}
            title="PDF Viewer"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        )}
      </Modal>



    </div>
  );
};

export default Appointments;
