import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  DatePicker,
  Radio,
  Checkbox,
  Dropdown,
  Space,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
const { Option } = Select;
const PreviewTemplate = ({
  open,
  onClose,
  onConfirm,
  generateFormStructure,
  templateName,
}) => {
  const formStructure = generateFormStructure(); // Call the function to get form structure
  const [formData, setFormData] = useState({}); // Store form input values

  // Handle input changes
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
// edited dropdown 
const [selectedValue, setSelectedValue] = useState("");

const dropdownItem = formStructure
  .flatMap((form) => form.children)
  .find((child) => child.type === "Dropdown");

const options = dropdownItem ? dropdownItem["data-source"] : [];

const handleDropdownChange = (value) => {
  setSelectedValue(value);
};
// end edited dropdown
  return (
    <Modal
      title="Preview"
      className="settings-file"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} className="me-3">
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
    >
      <div
        style={{
          border: "1px solid #eaeaea",
          background: "rgba(0,0,0,.3)",
          height: "550px",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          margin: "0 auto",
          width: "380px",
          marginBottom: "35px",
          marginTop: "35px",
        }}
      >
        <div
          style={{
            padding: "0px 0px 0px 0px",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              background: "#fff",
              height: "100%",
              width: "100%",
              borderRadius: "10px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              overflow: "auto",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className="w-100 text-center">
                <h4 className="template-heading">{templateName} </h4>
                <hr />
              </div>
              {formStructure[0].children.map((item, index) => {
                switch (item.type) {
                  case "TextHeading":
                    return (
                      <h1 key={index} className="large-heading">
                        {item.text}
                      </h1>
                    );
                  case "TextSubheading":
                    return (
                      <h2 key={index} className="small-heading">
                        {item.text}
                      </h2>
                    );
                  case "TextCaption":
                  case "TextBody":
                    return (
                      <p key={index} className="caption">
                        {item.text}
                      </p>
                    );
                  case "TextInput":
                    return (
                      <div key={index} className="short-answer mb-4">
                        <div className="floating-label">
                          <Input
                            id={item.name}
                            placeholder=" " // Keeps space for the label
                            defaultValue={formData[item.name] || ""}
                            onChange={(e) =>
                              handleInputChange(item.name, e.target.value)
                            }
                            onFocus={(e) =>
                              e.target.parentNode.classList.add("active")
                            }
                            onBlur={(e) => {
                              if (!e.target.value)
                                e.target.parentNode.classList.remove("active");
                            }}
                          />
                          <label htmlFor={item.name}>{item.label}</label>
                        </div>
                        {item["helper-text"] && <p className="helper-text">{item["helper-text"]}</p>}
                      </div>
                    );
                  case "TextArea":
                    return (
                      <div key={index} className="paragraph mb-4">
                        <div className="floating-label">
                          <Input.TextArea
                            placeholder=""
                            defaultValue={formData[item.name] || ""}
                            onChange={(e) =>
                              handleInputChange(item.name, e.target.value)
                            }
                            onFocus={(e) =>
                              e.target.parentNode.classList.add("active")
                            }
                            onBlur={(e) => {
                              if (!e.target.value)
                                e.target.parentNode.classList.remove("active");
                            }}
                          />
                          <label htmlFor={item.name}>{item.label}</label>
                        </div>
                        {item["helper-text"] && <p className="helper-text">{item["helper-text"]}</p>}
                      </div>
                    );
                  case "DatePicker":
                    return (
                      <div key={index} className="date mb-4">
                        <DatePicker
                          className="w-100"
                          onChange={(date, dateString) =>
                            handleInputChange(item.name, dateString)
                          }
                        />
                      </div>
                    );
                  case "RadioButtonsGroup":
                    return (
                      <div key={index} className="single-option">
                        {!item.label ? <p></p> : item.label !== item.type && <p>{item.label}</p>}
                        {/* {item.label !== item.type && <p>{item.label}</p>} */}
                        {item.description && <p className="description-text">{item.description}</p>}
                        <Radio.Group
                          className="d-flex flex-column"
                          onChange={(e) =>
                            handleInputChange(item.name, e.target.value)
                          }
                          defaultValue={formData[item.name] || ""}
                        >
                          {item["data-source"].map((option) => (
                            <Radio key={option.id} value={option.id}>
                              {option.title}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </div>
                      
                    );
                  case "CheckboxGroup":
                    return (
                      <div key={index} className="multiple-option">
                        {!item.label ? <p></p> : item.label !== item.type && <p>{item.label}</p>}
                        {item.description && <p className="description-text">{item.description}</p>}
                        <Checkbox.Group
                          options={item["data-source"].map((option) => ({
                            label: option.title,
                            value: option.id,
                          }))}
                          onChange={(checkedValues) =>
                            handleInputChange(item.name, checkedValues)
                          }
                        />
                      </div>
                    );
                  case "Dropdown":
                    return (
                        <>
                      {!item.label ? <p></p> : item.label !== item.type && <p>{item.label}</p>}
                      {/* <div key={index} className="dropdown-option">
                        <Dropdown
                          menu={{
                            items: item["data-source"].map((option) => ({
                              key: option.id,
                              label: option.title,
                            })),
                            onClick: ({ key }) =>
                              handleInputChange(item.name, key),
                          }}
                          trigger={["click"]}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              {formData[item.name] || "Select an option"}
                              <DownOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      </div> */}
                      <Select
                      style={{
                        padding: '0',
                        border: '0',
                        minHeight: '50px'
                      }}
                        className="form-control w-100"
                        placeholder="Select an option"
                        value={selectedValue || undefined}
                        onChange={handleDropdownChange}
                      >
                        {options.map((option) => (
                          <Option key={option.id} value={option.title}>
                            {option.title}
                          </Option>
                        ))}
                      </Select>
                      {item.description && <p className="description-text mt-2">{item.description}</p>}
                      </>
                    );
                  default:
                    return null;
                }
              })}
            </div>

            {/* ✅ Render Footer Button at the Bottom */}
            {formStructure[0].children.map(
              (item, index) =>
                item.type === "Footer" && (
                  <Button
                    key={index}
                    type="primary"
                    block
                    className="mt-4 preview-btn"
                    onClick={() => console.log("Form Submitted", formData)}
                  >
                    {item.label}
                  </Button>
                )
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewTemplate;
