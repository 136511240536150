import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Switch, message } from "antd";
import DataTable from "react-data-table-component";
import { get, post } from "../lib/HttpServices"; // Import your service file
import BxMenu from "../assets/images/bx-menu.svg"; // Ensure correct import

const TableWithColumnFilter = () => {
  // State to store dynamic columns
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch columns from API on component mount
  useEffect(() => {
    const fetchColumns = async () => {
      try {
        const response = await get("dashboard/columns/all");
        if (response?.data?.columns) {
          const formattedColumns = response.data.columns.map((col) => ({
            id: col.id,
            name: col.column_name,
            isVisible: col.is_visible,
            selector: (row) => row[col.column_name.toLowerCase()], // Match data key dynamically
          }));
          setColumns(formattedColumns);
        }
      } catch (error) {
        message.error("Failed to fetch columns");
      } finally {
        setLoading(false);
      }
    };

    fetchColumns();
  }, []);

  // Handle column visibility toggle
  const handleColumnToggle = async (checked, columnId) => {
    const updatedColumns = columns.map((col) =>
      col.id === columnId ? { ...col, isVisible: checked } : col
    );
    setColumns(updatedColumns);

    // Prepare payload for API
    const selectedIds = updatedColumns
      .filter((col) => col.isVisible)
      .map((col) => col.id);

    try {
      const response = await post("dashboard/columns/save", {
        selected_ids: selectedIds,
      });
      if (response?.data?.success) {
        message.success("Dashboard columns updated successfully");
      }
    } catch (error) {
      message.error("Failed to update column settings");
    }
  };

  // Filter only visible columns
  const visibleColumns = columns.filter((col) => col.isVisible);

  // Dropdown menu for column visibility
  const menu = (
    <Menu>
      {columns.map((col) => (
        <Menu.Item key={col.id}>
          <Switch
            checked={col.isVisible}
            onChange={(checked) => handleColumnToggle(checked, col.id)}
          />
          <label className="ms-3"> {col.name} </label>
        </Menu.Item>
      ))}
    </Menu>
  );

  // Dummy data for table (modify according to actual data structure)
  const dummyData = [
    {
      name: "Sachin",
      email: "sachin@example.com",
      mobile: "1635124253",
      location: "US",
      documents: "0 Documents",
    },
    {
      name: "Rahul",
      email: "rahul@example.com",
      mobile: "971501234567",
      location: "AE",
      documents: "0 Documents",
    },
  ];

  return (
    <div className="inside-box position-relative">
      <div className="table-custom">
        <Dropdown overlay={menu} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <img src={BxMenu} alt="Menu" style={{ width: "22px" }} />
          </a>
        </Dropdown>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <DataTable
          columns={visibleColumns}
          data={dummyData}
          highlightOnHover
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 20]}
          paginationComponentOptions={{
            rowsPerPageText: "Records per page:",
            rangeSeparatorText: "out of",
          }}
          className="table-style settings-tables"
        />
      )}
    </div>
  );
};

export default TableWithColumnFilter;
