import React, { useEffect, useState } from "react";
import LeadsImg from "../assets/images/leads.svg";
import CarbonLocation from "../assets/images/carbon_location.svg";
import { Dropdown, Space, Menu } from "antd";
import {
  Line,
  LineChart,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from "recharts";
import BarDiagram from "../components/BarDiagram";
import { DownOutlined } from "@ant-design/icons";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "../App.css";
import { get } from "../lib/HttpServices";
import CountUser from "../assets/images/count-users.svg";

const ReactGridLayout = WidthProvider(RGL);
const items = [
  {
    key: "4",
    label: "Last 7 Days",
    value: "Last 7 Days",
  },
  {
    key: "1",
    label: "Last 14 Days",
    value: "Last 14 Days",
  },
  {
    key: "2",
    label: "Last 30 Days",
    value: "Last 30 Days",
  },
  {
    key: "3",
    label: "Last 60 Days",
    value: "Last 60 Days",
  },
];

const DashboardWidgets = ({
  totalLeads,
  totalLocations,
  leadgraphData,
  LocationBarData,
  handleLeadMenuClick,
  handleLocationMenuClick,
  selected,
  selectedLocmenu,
}) => {
  const [widgets, setWidgets] = useState([]);
  const [headingLoc, setHeadingLoc] = useState("Locations");
  const [headingLeads, setHeadingLeads] = useState("Leads");
  const [headingUser, setHeadingUser] = useState("Users");
  const [registeredUsers, setRegisteredUsers] = useState(0);
  const [unRegisteredUsers, setUnRegisteredUsers] = useState(0);

  useEffect(() => {
    const fetchDashboardLayout = async () => {
      get(`dashboard/widgets`)
        .then((res) => {
          setWidgets(res.data.widgets);
          res.data.widgets.forEach((widget) => {
            if (widget.id === 1) setHeadingLeads(widget.name || "Leads");
            if (widget.id === 2) setHeadingLoc(widget.name || "Locations");
            if (widget.id === 3) setHeadingUser(widget.name || "Users");
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log("errr");
          }
        });
    };

    fetchDashboardLayout();
  }, []);



  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await get(`user_count`);
        if (response.status) {
          setRegisteredUsers(response.data.data.registered_users);
          setUnRegisteredUsers(response.data.data.unregistered_users);
        }
      } catch (error) {
        console.error("Error fetching user count:",error);   
      }
    };
    fetchUserCount();
  }, []);



  const leadMenu = (
    <Menu onClick={handleLeadMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const locationMenu = (
    <Menu onClick={handleLocationMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const renderWidgetComponent = (id) => {
    switch (id) {
      case 1:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>
                <h4 className="mb-0 ms-3">{headingLeads}</h4>
              </div>

              <div>
                <Dropdown overlay={leadMenu} trigger={["click"]} arrow>
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ cursor: "pointer", color: "#5562DA" }}
                  >
                    <Space>
                      {selected} {/* Display selected value */}
                      <DownOutlined style={{ color: "#5562DA" }} />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1">
              <div className="d-flex align-items-center justify-content-start">
                <div className="invisible">
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>
                <div className="ms-3">
                  <h1>{totalLeads}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Total Leads
                  </p>
                </div>
              </div>

              <ResponsiveContainer width="60%" height={200}>
                <LineChart data={leadgraphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#524bd6"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={CarbonLocation} alt="" style={{ width: "35px" }} />
                </div>
                <h4 className="mb-0 ms-3">{headingLoc}</h4>
              </div>
              <div>
                <Dropdown overlay={locationMenu} trigger={["click"]} arrow>
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ cursor: "pointer", color: "#5562DA" }}
                  >
                    <Space>
                      {selectedLocmenu} {/* Display selected value */}
                      <DownOutlined style={{ color: "#5562DA" }} />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="d-flex align-items-center justify-content-start">
                <div className="invisible">
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>
                <div className="ms-3">
                  <h1>{totalLocations}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Total Locations
                  </p>
                </div>
              </div>
              <BarDiagram data={LocationBarData} />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={CountUser} alt="" style={{ width: "30px" }} />
                </div>
                <h4 className="mb-0 ms-3">{headingUser}</h4>
              </div>
              {/* <div>
                <h5>hghgh</h5>
                </div> */}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 h-100">
              <div className="d-flex align-items-center justify-content-start mb-4">
                {/* <div className="invisible">
                      <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                    </div> */}
                <div className="ms-3">
                  <h1>{registeredUsers}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Registered Users
                  </p>
                </div>
                <div className="ms-3">
                  <h1>{unRegisteredUsers}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Unregistered Users
                  </p>
                </div>
              </div>
              {/* <BarDiagram data={LocationBarData} /> */}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const layout = widgets.map((widget) => ({
    ...widget.layout,
    i: widget.id.toString(), // Ensure it's a string
  }));

  return (
    <ReactGridLayout
      className="layout"
      layout={layout}
      cols={4}
      rowHeight={250}
      width={1200}
      isDraggable={false} // Disable dragging
      isResizable={false} // Disable resizing
    >
      {widgets.map((widget) => (
        <div key={widget.id}>{renderWidgetComponent(widget.id)}</div>
      ))}
    </ReactGridLayout>
  );
};

export default DashboardWidgets;
