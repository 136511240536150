import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { DatePicker } from "antd";
import Header from "../components/Header";
import { Input } from "antd";
import LeadsImg from "../assets/images/leads.svg";
import RegisteredImg from "../assets/images/registered.svg";
import UnregisteredImg from "../assets/images/registered.svg";
import CarbonLocation from "../assets/images/carbon_location.svg";
import sortImg from "../assets/images/sort.svg";
import dateImg from "../assets/images/date.svg";
import SearchImg from "../assets/images/search.svg";
import { Button, Dropdown, Space, Menu, Modal, Form, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import BarDiagram from "../components/BarDiagram";
import ChatBot from "../components/ChatBot";
import DynamicTable from "../components/DynamicTable";
import DataTable from "react-data-table-component";
import DocumentMenuImg from "../assets/images/drpdown-menu.svg";
import LocationCheckboxDropdown from "../components/filterByLocation";
import FilterByDate from "../components/filterByDate";
import { useNavigate } from "react-router-dom";
import { get, post } from "../lib/HttpServices";
import DownloadMenuImg from "../assets/images/download.png";
import axios from "axios";
import { toast } from "react-toastify";
import { parse, format } from "date-fns";
import DocumentImg from "../assets/images/document.svg";
import ViewMenuImg from "../assets/images/view.png";
import DocumentTextImg from "../assets/images/document-text.svg";
import MessageTextImg from "../assets/images/message-chat.svg";
import DeleteDashboardImg from "../assets/images/delete-dashboard.svg";

// import { Line } from '@ant-design/charts';
import {
  Line,
  LineChart,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from "recharts";
import DashboardWidgets from "../components/DashboardWidgets";

const items = [
  {
    key: "4",
    label: "Last 7 Days",
    value: "Last 7 Days",
  },
  {
    key: "1",
    label: "Last 14 Days",
    value: "Last 14 Days",
  },
  {
    key: "2",
    label: "Last 30 Days",
    value: "Last 30 Days",
  },
  {
    key: "3",
    label: "Last 60 Days",
    value: "Last 60 Days",
  },
];

const Dashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selected, setSelected] = useState("Last 7 Days"); // Initial selected value
  const [selectedLocmenu, setselectedLocmenu] = useState("Last 7 Days"); // Initial selected value
  const [totalLeadPercentage, setotalLeadPercentage] = useState("51");
  const [totalLeads, setotalLead] = useState("--");
  const [locationfullList, setlocationfullList] = useState([]);
  const [leadgraphData, setleadgraphData] = useState([]);
  const [totalLocations, setotalocations] = useState("--");
  const [LocationBarData, setlocationbardata] = useState([]);
  const [leadCustomerData, setleadCustomerData] = useState([]);
  const [filterCustomerData, setfilterCustomerData] = useState([]);
  const [fiterText, setfiterText] = useState("");
  const [selectedLocations, setselectedLocations] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  const lead_opt_dict = {
    "Last 7 Days": "7",
    "Last 4 Days": "4",
    "Last 2 Days": "2",
    "Last 1 Day": "1",
    "Last 14 Days": "14",
    "Last 30 Days": "30",
    "Last 60 Days": "60",
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedlisteofdoc, setselectedlisteofdoc] = useState([]);
  const [selectedlisteofdoc_adduser, setselectedlisteofdoc_adduser] = useState(
    []
  );
  const [dropdownOpenRow, setDropdownOpenRow] = useState("");
  const [requestedpatientContactNumber, setRequestedpatientContactNumber] =
    useState("");
  const [requestedpatientName, setRequestedpatientName] = useState("");
  const [
    selectedDefaultRequestedlisteofdoc,
    setselectedDefaultRequestedlisteofdoc,
  ] = useState([]);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [modalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [fileType, setFileType] = useState("");
  const [listofdoc, setListsofDoc] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientName, setpatientName] = useState("");
  const [patientContactNumber, setpatientContactNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(15);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const leadData = { total_leads: 136, percentage: 51 };

  const myNewTheme = {
    rows: {
      fontSize: "50px",
    },
  };
  //
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const validateInputs = () => {
    const newErrors = {};

    // Validate name
    if (!patientName.trim()) {
      newErrors.patientName = "Name is required.";
    }
    if (!/^[A-Za-z\s]{2,120}$/.test(patientName)) {
      newErrors.patientName =
        "Name must be between 2 and 120 characters and contain only letters.";
    }

    // Validate phone number
    const phoneRegex = /^[+]?[0-9]{10,15}$/;
    if (!patientContactNumber.trim()) {
      newErrors.patientContactNumber = "Phone number is required.";
    } else if (!phoneRegex.test(patientContactNumber)) {
      newErrors.patientContactNumber =
        "Phone number must be between 10 and 15 digits, including country code!";
    }

    // Validate document selection
    if (selectedlisteofdoc.length === 0) {
      newErrors.selectedlisteofdoc = "At least one document must be selected.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    get(`get_documents_list`)
      .then((res) => {
        setListsofDoc(res.data.documents);
      })
      .catch((error) => {
        if (error.response) {
          console.log("errr");
        }
      });
  }, []);

  useEffect(() => {
    const fetchVisibleColumns = async () => {
      try {
        const response = await get("dashboard/columns/visible");
        if (response?.data?.columns) {
          setVisibleColumns(response.data.columns);
        }
      } catch (error) {
        console.error("Error fetching visible columns:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisibleColumns();
  }, []);

  const handleOk = () => {
    if (validateInputs()) {
      let payload = {
        user_name: patientName,
        phone_number: patientContactNumber,
        documents: selectedlisteofdoc.map((id) => ({
          document_name:
            listofdoc.find((doc) => doc.id === id)?.title || "Unknown",
          status: "requested",
        })),
      };
      console.log("add_user::", payload);
      post(`add_user`, payload)
        .then((res) => {
          console.log(res);
          toast.success(res.data?.message, {
            autoClose: 3000,
          });
          setIsModalOpen(false);
        })
        .catch((error) => console.error("API Error::", error));
    }
  };

  const handlePatientName = (e) => {
    setpatientName(e.target.value);
  };

  const handleChange = (value) => {
    // Filter selected values to ensure only available options are chosen
    const validValues = value.filter((val) =>
      options.some((option) => option.value === val)
    );

    console.log("Updated selected documents:", validValues);
    setselectedlisteofdoc(validValues); // Update state with only valid values
  };

  const handleSave_adduser_form = () => {
    if (validateInputs()) {
      let payload = {
        user_name: patientName,
        phone_number: patientContactNumber,
        documents: selectedlisteofdoc.map((id) => ({
          document_name:
            listofdoc.find((doc) => doc.id === id)?.title || "Unknown",
          status: "requested",
        })),
      };
      console.log("add_user::", payload);
      post(`add_user`, payload)
        .then((res) => {
          console.log(res);
          toast.success(res.data?.message, {
            autoClose: 3000,
          });
          handleCancel_adduser();
        })
        .catch((error) => console.error("API Error::", error));
    }
  };

  const handleCancel_AddUser_form = () => {
    setIsModalVisible(false);
    form.resetFields();
    handleCancel_adduser();
  };

  const handleCancel = () => {
    console.log("Cancel ");
    setIsModalOpen(false);
    setpatientName("");
    setpatientContactNumber("");
    setselectedlisteofdoc([]);
    setErrors({});
  };

  const handleCancel_adduser = () => {
    console.log("Cancel ");
    setIsModalVisible(false);
    setpatientName("");
    setpatientContactNumber("");
    setselectedlisteofdoc([]);
    setErrors({});
  };
  //
  const dropdownRef = useRef(null);

  const handleRequestDocument = (row) => {
    console.log("row::", row);

    setpatientName(row.name);
    setpatientContactNumber(row.mobile);
    // Ensure requested_documents is defined and is an array before mapping
    const defaultDocuments = Array.isArray(row.requested_documents)
      ? row.requested_documents.map((doc) => doc.document_name)
      : [];
    console.log("defaultDocuments-----", defaultDocuments);
    setselectedlisteofdoc(defaultDocuments);
    setIsModalOpen(true);
    console.log("defaultDocuments:-:", defaultDocuments);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    if (startDate && endDate) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const startDateString_formatted = `${startDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      const endDateString_formatted = `${endDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      setDateRange({ startDateString_formatted, endDateString_formatted });
    } else {
      // Handle case when either or both dates are null (reset)
      setDateRange({
        startDateString_formatted: null,
        endDateString_formatted: null,
      });
    }
  };

  const handleViewClick = (lead_id, name, date, location) => {
    // Navigate to the ChatView component
    navigate(`/chatbot/${lead_id}`, {
      state: { lead_id, name, date, location }, // Pass customer_id and name as state
    });
  };

  const documentMenu = (row) => (
    <Menu>
      {Array.isArray(row.documents) && row.documents.length > 0 ? (
        row.documents.map((doc) => (
          <Menu.Item key={doc.file_id} className="doc-lits">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "180px",
                  marginRight: "10px",
                  background: "rgb(241, 244, 248)",
                  borderRadius: "50px",
                  padding: "8px 11px",
                }}
              >
                <img
                  src={DocumentImg}
                  alt=""
                  style={{ width: "14px", marginRight: "8px" }}
                />
                {doc.file_name}
              </span>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <div
                  style={{
                    background: "rgb(241, 244, 248)",
                    borderRadius: "50px",
                    // margin: '0px 10px',
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={ViewMenuImg}
                    alt="View"
                    style={{
                      width: "20px",
                      cursor: "pointer",
                      background: "rgb(241, 244, 248)",
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setModalOpen(true);
                      setImageSrc(doc.file_path);
                      setFileType(doc.file_type);
                    }}
                  />
                </div>
                <div
                  style={{
                    background: "rgb(241, 244, 248)",
                    borderRadius: "50px",
                    // margin: '0px 10px',
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={DownloadMenuImg}
                    alt="Download"
                    style={{ width: "18px", cursor: "pointer" }}
                    onClick={async () => {
                      try {
                        const response = await fetch(doc.file_path);
                        if (!response.ok)
                          throw new Error("Failed to download file");
                        const blob = await response.blob();
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = doc.file_name || "downloaded_file";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href);
                      } catch (error) {
                        console.error("Error downloading file:", error);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>No documents available</Menu.Item>
      )}
    </Menu>
  );

  //
  const columns = [
    {
      name: "Date",
      selector: (row) => parse(row.date, "dd MMM yyyy, hh:mm a", new Date()), // Parse based on the date format
      // cell: (row) => <div>{format(parse(row.date, 'dd MMM yyyy, hh:mm a', new Date()), 'PPpp')}</div>, // Reformat if needed
      cell: (row) => <div>{row.date}</div>,
      sortable: true,
    },

    {
      name: <div>Mobile</div>,
      selector: (row, i) => row.mobile,
      // cell: (row) => <div>{row.lastName}</div>,
      sortable: true,
    },
    {
      name: <div>Location</div>,
      selector: (row, i) => row.location,
      sortable: true,
    },
    {
      name: <div>Name</div>,
      //  selector: "lastName",
      selector: (row, i) => row.name,
      // cell: (row) => <div>{row.lastName}</div>,
      sortable: true,
    },
    {
      name: "Documents",
      selector: (row) => row.documents,
      minWidth: "300px",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "60%",
          }}
        >
          <span style={{ minWidth: "100px" }}>
            {row.documents?.length || 0} Documents
          </span>
          <Dropdown
            overlay={documentMenu(row)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img
              src={DocumentMenuImg}
              alt="Menu"
              style={{ width: "25px", marginLeft: "15px", cursor: "pointer" }}
            />
          </Dropdown>
          <button
            className="btn custom-btn-sec"
            style={{ marginLeft: "15px", minWidth: "125px" }}
            onClick={() => handleRequestDocument(row)}
          >
            Request{" "}
            <img
              src={DocumentImg}
              alt="document"
              style={{ width: "12px" }}
              className="ms-2"
            />
          </button>
        </div>
      ),
    },
    {
      name: "Actions",
      // width: "4rem",
      center: true,
      selector: (row) => (
        <div className="d-flex align-items-center">
          <button className="btn custom-btn-sec no-border no-padding">
            <img src={DocumentTextImg} alt="" />
          </button>
          <div className="border-right-line-table"></div>
          <button
            className="btn custom-btn-sec no-border no-padding"
            onClick={() =>
              handleViewClick(row.mobile, row.name, row.date, row.location)
            }
          >
            <img src={MessageTextImg} alt="" />
          </button>
        </div>
      ),
    },
  ];

  // Normalize API column names (convert to match allColumns keys)
  const normalizeColumnName = (columnName) =>
    columnName.replace(/\s+/g, "_").toLowerCase();

  // Define all possible columns (Predefined)
  const allColumns = {
    date: {
      name: "Date",
      selector: (row) => parse(row.date, "dd MMM yyyy, hh:mm a", new Date()),
      cell: (row) => <div>{row.date}</div>,
      sortable: true,
    },
    phone_number: {
      name: <div>Mobile</div>,
      selector: (row) => row.mobile,
      sortable: true,
    },
    location: {
      name: <div>Location</div>,
      selector: (row) => row.location,
      sortable: true,
    },
    name: {
      name: <div>Name</div>,
      selector: (row) => row.name,
      sortable: true,
    },
    documents: {
      name: "Documents",
      selector: (row) => row.documents,
      minWidth: "300px",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "60%",
          }}
        >
          <span style={{ minWidth: "100px" }}>
            {row.documents?.length || 0} Documents
          </span>
          <Dropdown
            overlay={documentMenu(row)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img
              src={DocumentMenuImg}
              alt="Menu"
              style={{ width: "25px", marginLeft: "15px", cursor: "pointer" }}
            />
          </Dropdown>
          <button
            className="btn custom-btn-sec"
            style={{ marginLeft: "15px", minWidth: "125px" }}
            onClick={() => handleRequestDocument(row)}
          >
            Request{" "}
            <img
              src={DocumentImg}
              alt="document"
              style={{ width: "12px" }}
              className="ms-2"
            />
          </button>
        </div>
      ),
    },
    actions: {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <button className="btn custom-btn-sec no-border no-padding">
            <img src={DocumentTextImg} alt="view" />
          </button>
          <div className="border-right-line-table"></div> */}
          <button
            className="btn custom-btn-sec no-border no-padding"
            onClick={() =>
              handleViewClick(row.mobile, row.name, row.date, row.location)
            }
          >
            <img src={MessageTextImg} alt="message" />
          </button>
          {/* <div className="border-right-line-table"></div>
          <button className="btn custom-btn-sec no-border no-padding">
            <img src={DeleteDashboardImg} alt="" />
          </button> */}
        </div>
      ),
    },
  };

  // Convert API response to mapped column structure
  let mappedColumns = visibleColumns.map((col) => {
    const columnKey = normalizeColumnName(col.column_name);

    if (allColumns[columnKey]) {
      return allColumns[columnKey]; // Use predefined column
    } else {
      // Create a new column dynamically if not in allColumns
      return {
        name: <div>{col.column_name}</div>,
        selector: (row) => row[columnKey], // Handle key naming
        sortable: true,
      };
    }
  });

  // Ensure "Documents" column is included only if it's NOT in API response
  if (
    !visibleColumns.some(
      (col) => normalizeColumnName(col.column_name) === "documents"
    )
  ) {
    mappedColumns.push(allColumns.documents);
  }

  // Always include "Actions" column
  mappedColumns.push(allColumns.actions);

  const fetchData = () => {
    const { startDateString_formatted, endDateString_formatted } = dateRange;

    if (startDateString_formatted && endDateString_formatted) {
      get(
        `get_dashboard_users?start_date=${startDateString_formatted}&end_date=${endDateString_formatted}`
      )
        .then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);

          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        })
        .catch((error) => {
          console.error("Error fetching data with date range:", error);
        });
    } else {
      get("get_dashboard_users")
        .then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);

          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        })
        .catch((error) => {
          console.error("Error fetching data without date range:", error);
        });
    }
  };

  const handleLocationChange = (selectedLocations) => {
    const temp_selected_locations = selectedLocations;
    setselectedLocations(temp_selected_locations);
  };

  const options = listofdoc.map((doc) => ({
    value: doc.id,
    label: doc.title,
  }));

  const handleRefresh = () => {
    fetchData();
    setTimer(15); // Reset timer on manual refresh
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up interval for repeated fetch
    const intervalId = setInterval(() => {
      fetchData();
      setTimer(15); // Reset timer after fetch
    }, 15000);

    // Countdown logic
    const countdownInterval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
      clearInterval(countdownInterval);
    };
  }, [dateRange]);

  useEffect(() => {
    get(`get_dashboard_leads?days=${lead_opt_dict[selected]}`)
      .then((res) => {
        setotalLead(res.data.data.total_leads);
        setleadgraphData(res.data.data.leads);
        setotalLeadPercentage(leadData.percentage);
      })
      .catch((error) => {
        if (error.response) {
          console.log("errr");
        }
      });
  }, [selected]);

  useEffect(() => {
    // Filter customer data based on selected locations
    if (selectedLocations.length > 0) {
      const filteredData = leadCustomerData.filter((customer) =>
        selectedLocations.includes(customer.location)
      );
      setfilterCustomerData(filteredData);
    } else {
      // If no locations are selected, show all data
      setfilterCustomerData(leadCustomerData);
    }
  }, [selectedLocations]);

  useEffect(() => {
    // Filter customer data based on selected locations
    //
    get(`get_dashboard_locations?days=${lead_opt_dict[selectedLocmenu]}`)
      .then((res) => {
        // console.log("Data:", res.data.data); // Log the data field explicitly
        setotalocations(res.data.data.total_locations);
        setlocationbardata(res.data.data.bardiagram_data);
      })
      .catch((error) => {
        if (error.response) {
          console.log("errr in dashbord_leads");
        }
      });
  }, [selectedLocmenu]);

  const onChangeFilter = async (e) => {
    // debugger;
    const temp_filter_text = e.target.value;
    setfiterText(temp_filter_text);

    // Clear the date filter field when typing in the search input

    var searchData = leadCustomerData.filter((item) => {
      if (
        // item?.firstName
        //   .toString()
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase())
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      ) {
        return item;
      }
    });

    setfilterCustomerData(searchData);
  };

  const sortByDate = (data) => {
    return data.sort((a, b) => {
      // Function to convert "20 Jan 2004, 8:00PM" into a Date object
      const parseDate = (dateString) => {
        const [datePart, timePart] = dateString.split(", ");
        const [day, month, year] = datePart.split(" ");
        const time = timePart.replace("PM", " PM").replace("AM", " AM"); // Ensure correct time format

        // Months map for converting "Jan", "Feb", etc., into numbers
        const months = {
          Jan: 0,
          Feb: 1,
          Mar: 2,
          Apr: 3,
          May: 4,
          Jun: 5,
          Jul: 6,
          Aug: 7,
          Sep: 8,
          Oct: 9,
          Nov: 10,
          Dec: 11,
        };

        return new Date(`${year}-${months[month] + 1}-${day} ${time}`);
      };

      // Convert both dates and compare them
      const dateA = parseDate(a.date);
      const dateB = parseDate(b.date);

      return dateB - dateA; // Sort in descending order (latest date first)
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenRow(null); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const { startDateString_formatted, endDateString_formatted } = dateRange;
    if (startDateString_formatted && endDateString_formatted) {
      if (startDateString_formatted && endDateString_formatted) {
        get(
          `get_dashboard_users?start_date=${startDateString_formatted}&end_date=${endDateString_formatted}`
        ).then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);
          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        });
      }
    } else {
      get("get_dashboard_users")
        .then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);
          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        })
        .catch((error) => {
          console.error("Error during API call:", error);
        });
    }
  }, [dateRange]);

  useEffect(() => {
    get("get_locations").then((res) => {
      setlocationfullList(res.data.data.locations);
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleLeadMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setSelected(selectedItem.value); // Update selected value
      // console.log(selectedItem)
    }
  };

  const handleLocationMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setselectedLocmenu(selectedItem.value); // Update selected value
    }
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />

        <div className="project-task">
          <DashboardWidgets
            totalLeads={totalLeads}
            totalLocations={totalLocations}
            leadgraphData={leadgraphData}
            LocationBarData={LocationBarData}
            handleLeadMenuClick={handleLeadMenuClick}
            handleLocationMenuClick={handleLocationMenuClick}
            selected={selected}
            selectedLocmenu={selectedLocmenu}
          />
          {/*  */}
         
          {/*  */}
          <div className="row my-4 m-0">
            <div className="col-lg-12">
              {/*  */}
              <div className="d-flex align-items-center justify-content-end">
                <div>
                  <form action="">
                    <div
                      className="bg-light rounded rounded-pill shadow-sm me-3"
                      style={{ border: "1px solid #DEDEDE", minWidth: "370px" }}
                    >
                      <div className="input-group search-space">
                        <Input
                          type="search"
                          placeholder="Search with Name,Location or Date"
                          value={fiterText}
                          onChange={onChangeFilter}
                          aria-describedby="button-addon1"
                          className=" ms-2 form-control rounded-pill border-0 bg-light"
                        />
                        <div className="input-group-append">
                          <button
                            id="button-addon1"
                            type="submit"
                            className="btn btn-link text-primary border-0"
                          >
                            <img src={SearchImg} alt="" className="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div>
                  <LocationCheckboxDropdown
                    locations={locationfullList}
                    onLocationChange={handleLocationChange}
                  />
                </div>

                <div>
                  <FilterByDate onDateRangeChange={handleDateRangeChange} />
                </div>

                <div>
                  <button className="btn btn-primary ms-4" onClick={showModal}>
                    New User
                  </button>
                </div>

                {/* modal */}

                <Modal
                  // title="Add User"
                  open={isModalVisible}
                  title={
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span>Add User</span>
                      <div className=" modal-actions">
                        <button
                          onClick={handleCancel_AddUser_form}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSave_adduser_form}
                          type="button"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  }
                  footer={null}
                  closable={false}
                  onCancel={handleCancel_AddUser_form}
                >
                  <div className="form-group mt-3">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      value={patientName}
                      onChange={handlePatientName}
                    />
                    {errors.patientName && (
                      <span className="text-danger">{errors.patientName}</span>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <label>Mobile</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Mobile Number"
                      value={patientContactNumber}
                      disabled={isEdit}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^\d*$/.test(input)) {
                          setpatientContactNumber(input);
                        }
                      }}
                    />
                    {errors.patientContactNumber && (
                      <span className="text-danger">
                        {errors.patientContactNumber}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 mb-4">
                    <label>List of Documents</label>
                    <Select
                      mode="multiple" // Changed from "tags" to "multiple"
                      style={{ width: "100%" }}
                      placeholder="Select documents"
                      value={selectedlisteofdoc}
                      onChange={handleChange}
                      options={options}
                      showSearch={true} // Enables filtering of available options
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                    {errors.selectedlisteofdoc && (
                      <span className="text-danger">
                        {errors.selectedlisteofdoc}
                      </span>
                    )}
                  </div>
                </Modal>

                {/* end modal */}
              </div>
            </div>
          </div>
          {/* <!--  --> */}
          <div className="row m-0">
            <div className="col-lg-12">
              {/* <DynamicTable data={filterCustomerData} /> */}

              <Modal
                title={
                  <div className="modal-headers">
                    <span>{isEdit ? "Edit Details" : "Edit User"}</span>
                    <div className="modal-actions">
                      <button
                        className="btn btn-secondary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary" onClick={handleOk}>
                        Save
                      </button>
                    </div>
                  </div>
                }
                open={isModalOpen}
                onCancel={handleCancel}
                closable={false} // Removes the close icon
                footer={null} // Hides default footer buttons
                className="add-patient"
              >
                <div className="form-group mt-3">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    value={patientName}
                    onChange={handlePatientName}
                  />
                  {errors.patientName && (
                    <span className="text-danger">{errors.patientName}</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label>Mobile</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Mobile Number"
                    value={patientContactNumber}
                    disabled={true}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d*$/.test(input)) {
                        setpatientContactNumber(input);
                      }
                    }}
                  />
                  {errors.patientContactNumber && (
                    <span className="text-danger">
                      {errors.patientContactNumber}
                    </span>
                  )}
                </div>
                <div className="mt-3 mb-4">
                  <label>List of Documents</label>
                  <Select
                    mode="multiple" // Changed from "tags" to "multiple"
                    style={{ width: "100%" }}
                    placeholder="Select documents"
                    value={selectedlisteofdoc}
                    onChange={handleChange}
                    options={options}
                    showSearch={true} // Enables filtering of available options
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                  {errors.selectedlisteofdoc && (
                    <span className="text-danger">
                      {errors.selectedlisteofdoc}
                    </span>
                  )}
                </div>
              </Modal>

              <DataTable
                columns={mappedColumns}
                data={filterCustomerData}
                highlightOnHover
                customTheme={myNewTheme}
                pagination
                options={{
                  disableSelectionOnClick: true,
                  noHeader: true,
                }}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
                className="table-style settings-tables"
              />

              <div
                style={{
                  position: "absolute",
                  bottom: "25px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "15px",
                }}
              >
                <button className="btn btn-primary" onClick={handleRefresh}>
                  Refresh
                </button>
                <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                  Next auto-refresh in: {timer}s
                </span>
              </div>
            </div>
          </div>

          {/* <ChatBot /> */}
        </div>
        {/* <!--  --> */}
      </section>
      <Modal
        title="Uploaded Document"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {fileType !== "pdf" ? (
          imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              style={{ width: "100%", height: "500px", objectFit: "contain" }}
            />
          ) : (
            <p>No image to show</p>
          )
        ) : (
          <iframe
            src={imageSrc}
            title="PDF Viewer"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
