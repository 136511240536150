import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import SearchImg from "../assets/images/search.svg";
import { Input, Modal } from "antd";
import { post, get, remove } from "../lib/HttpServices";
import { Tooltip } from "react-tooltip";
import { parse, format } from "date-fns";
import LocationCheckboxDropdown from "../components/filterByLocation";
import FilterByDate from "../components/filterByDate";
import DeleteImg from "../assets/images/delete.png";
import { toast } from "react-toastify";

const RegisteredUser = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [registered_user_count, setregistered_user_count] = useState("0");
  const [registered_user_data, setregistered_user_data] = useState([]);
  const [registered_user_filtered_data, setregistered_user_filtered_data] =
    useState([]);
  const [fiterText, setfiterText] = useState("");
  const [locationfullList, setlocationfullList] = useState([]);
  const [selectedLocations, setselectedLocations] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserMobile, setSelectedUserMobile] = useState(null);

  // const [userQData, setuserQData] = useState([]);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  // useEffect(() => {
  //     get(`get_registered_users`)
  //         .then((res) => {
  //             const temp_data = res.data.data
  //             const sorted_data = sortByDate(temp_data)
  //             setregistered_user_count(temp_data.length)
  //             setregistered_user_data(sorted_data)
  //             setregistered_user_filtered_data(sorted_data)

  //         })
  //         .catch((error) => {
  //           if (error.response) {
  //           console.log("errr ");
  //           }
  //         });
  //     // setregistered_user_data(data)
  //     // setregistered_user_filtered_data(data)

  // }, []);

  useEffect(() => {
    const { startDateString_formatted, endDateString_formatted } = dateRange;
    if (startDateString_formatted && endDateString_formatted) {
      // console.log("yeah it has date range",dateRange)

      if (startDateString_formatted && endDateString_formatted) {
        get(
          `get_registered_users?start_date=${startDateString_formatted}&end_date=${endDateString_formatted}`
        ).then((res) => {
          //   const temp_user_data = res.data.data
          //   setleadCustomerData(temp_user_data)
          // const sorted_data = sortByDate(temp_user_data)
          // setfilterCustomerData(sorted_data)
          const temp_data = res.data.data;
          const sorted_data = sortByDate(temp_data);
          setregistered_user_count(temp_data.length);
          setregistered_user_data(sorted_data);
          setregistered_user_filtered_data(sorted_data);
        });
      }
    } else {
      get(`get_registered_users`)
        .then((res) => {
          const temp_data = res.data.data;
          const sorted_data = sortByDate(temp_data);
          setregistered_user_count(temp_data.length);
          setregistered_user_data(sorted_data);
          setregistered_user_filtered_data(sorted_data);
        })
        .catch((error) => {
          if (error.response) {
            console.log("errr ");
          }
        });
    }
    // setregistered_user_data(data)
    // setregistered_user_filtered_data(data)
  }, [dateRange]);

  useEffect(() => {
    get("get_locations").then((res) => {
      setlocationfullList(res.data.data.locations);
    });
  }, []);

  useEffect(() => {
    // Filter customer data based on selected locations
    if (registered_user_data.length > 0) {
      if (selectedLocations.length > 0) {
        const filteredData = registered_user_data.filter((customer) =>
          selectedLocations.includes(customer.location)
        );
        setregistered_user_filtered_data(filteredData);
        setregistered_user_count(filteredData.length);
      } else {
        // If no locations are selected, show all data
        setregistered_user_filtered_data(registered_user_data);
        setregistered_user_count(registered_user_data.length);
      }
    }
  }, [selectedLocations]);

  const handleUserDelete = (row) => {
    setSelectedUserMobile(row.mobile);
    setIsModalOpen(true);
  };

  // const handleUserListDelete = async () => {
  //     if (!selectedTemplateId) return; // Ensure an ID is selected

  //     try {
  //       await post(`/delete_user/<phone_number>/${selectedTemplateId}`);
  //       setIsModalOpen(false);
  //       fetchTemplateList();
  //     } catch (error) {
  //       console.error("Error deleting template:", error);
  //     }
  //   };

  const handleUserListDelete = async () => {
    if (!selectedUserMobile) return;

    try {
      await remove(`delete_user/${selectedUserMobile}`);
      setIsModalOpen(false);
      setregistered_user_filtered_data((prevData) =>
        prevData.filter((user) => user.mobile !== selectedUserMobile)
      );
      toast.success("User deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user!", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const data = [
    {
      id: 1,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 2,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 3,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 4,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 5,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 6,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 7,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 8,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 9,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 10,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 11,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 12,
      mobile: "9547456544",
      name: "Sahi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
    {
      id: 13,
      mobile: "0947456544",
      name: "Sachi",
      location: "",
      role: "",
      domain: "",
      requirements: "",
    },
  ];

  const handleLocationChange = (selectedLocations) => {
    const temp_selected_locations = selectedLocations;
    setselectedLocations(temp_selected_locations);
  };

  const sortByDate = (data) => {
    return data.sort((a, b) => {
      // Function to convert "20 Jan 2004, 8:00PM" into a Date object
      const parseDate = (dateString) => {
        const [datePart, timePart] = dateString.split(", ");
        const [day, month, year] = datePart.split(" ");
        const time = timePart.replace("PM", " PM").replace("AM", " AM"); // Ensure correct time format

        // Months map for converting "Jan", "Feb", etc., into numbers
        const months = {
          Jan: 0,
          Feb: 1,
          Mar: 2,
          Apr: 3,
          May: 4,
          Jun: 5,
          Jul: 6,
          Aug: 7,
          Sep: 8,
          Oct: 9,
          Nov: 10,
          Dec: 11,
        };

        return new Date(`${year}-${months[month] + 1}-${day} ${time}`);
      };

      // Convert both dates and compare them
      const dateA = parseDate(a.date);
      const dateB = parseDate(b.date);

      return dateB - dateA; // Sort in descending order (latest date first)
    });
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // selector: (row) => parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()),
      // cell: (row) => <div>{row.date_time}</div>,
      // sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.mobile,
      //   selector: row => row.phone_number,
      //   sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      // selector: row => row.user_name,
      // sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      // selector: row => row.message_text,
      // sortable: true,
      // width: '300px',
      // cell: row => (
      //     <>
      //         <div
      //             data-tooltip-id={`tooltip-${row.message_text}`}
      //             data-tooltip-content={row.message_text}
      //             style={{
      //                 overflow: 'hidden',
      //                 textOverflow: 'ellipsis',
      //                 whiteSpace: 'nowrap',
      //                 width: '280px',
      //             }}
      //         >
      //             {row.message_text}
      //         </div>
      //         <Tooltip
      //             id={`tooltip-${row.message_text}`}
      //             style={{
      //                 maxWidth: '400px',  // Adjust this as needed
      //                 whiteSpace: 'normal',
      //                 wordWrap: 'break-word',
      //             }}
      //             place="top" // You can change the position as needed
      //         />
      //     </>
      // )
    },
    {
      name: "Domain",
      selector: (row) => row.domain,
      // selector: row => row.admin_reply_text,
      // sortable: true,
      // width: '200px',
    },
    {
      name: "Requirements",
      selector: (row) => row.requirements,
      // selector: row => row.status,
      // sortable: true,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => (
        <div>
          <button
            onClick={() => handleUserDelete(row)}
            className="btn custom-btn-sec no-border no-padding"
          >
            <img src={DeleteImg} alt="Delete" />
          </button>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //     get(`get_registered_users`)
  //     .then((res) => {
  //         console.log("xecue");
  //         setuserQData(res.data.data.user_queries_list)

  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //       console.log("errr ");
  //       }
  //     });
  //  }, []);

  const onChangeFilter = async (e) => {
    // debugger;
    const temp_filter_text = e.target.value;
    setfiterText(temp_filter_text);

    // Clear the date filter field when typing in the search input

    var searchData = registered_user_data.filter((item) => {
      if (
        // item?.firstName
        //   .toString()
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase())
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      ) {
        return item;
      }
    });

    setregistered_user_filtered_data(searchData);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    if (startDate && endDate) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      // const startDateString = startDate.toString();
      // const endDateString = endDate.toString();
      const startDateString_formatted = `${startDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      const endDateString_formatted = `${endDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      setDateRange({ startDateString_formatted, endDateString_formatted });
    } else {
      // Handle case when either or both dates are null (reset)
      setDateRange({
        startDateString_formatted: null,
        endDateString_formatted: null,
      });
    }
  };

  const closeDateRangeFilter = () => {
    setDateRange({
      startDateString_formatted: null,
      endDateString_formatted: null,
    });
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header
          toggleSidebar={toggleSidebar}
          reg_user_count={registered_user_count}
        />
        <div className="project-task">
          <div class="inside-box p-4">
            <div className="d-flex align-items-center justify-content-end w-100 mb-4">
              {/*  */}
              <div className="d-flex align-items-center justify-content-between">
                <form action="">
                  <div
                    className="bg-light rounded rounded-pill shadow-sm me-3"
                    style={{ border: "1px solid #DEDEDE", minWidth: "370px" }}
                  >
                    <div className="input-group search-space">
                      <Input
                        type="search"
                        placeholder="Search with Name,Location or Date"
                        value={fiterText}
                        onChange={onChangeFilter}
                        aria-describedby="button-addon1"
                        className=" ms-2 form-control rounded-pill border-0 bg-light"
                      />
                      <div className="input-group-append">
                        <button
                          id="button-addon1"
                          type="submit"
                          className="btn btn-link text-primary border-0"
                        >
                          <img src={SearchImg} alt="" className="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <LocationCheckboxDropdown
                  locations={locationfullList}
                  onLocationChange={handleLocationChange}
                />
                <FilterByDate onDateRangeChange={handleDateRangeChange} />
              </div>
              {/*  */}
              {/* <div>
                                    <LocationCheckboxDropdown locations={locationfullList} onLocationChange={handleLocationChange} />
                                    </div> */}
              {/*  */}
              {/* <div>
                                        <FilterByDate onDateRangeChange={handleDateRangeChange} />
                                     </div> */}
            </div>
            {/* <h4>User Queries</h4> */}
            <Modal
              title={
                <div className="modal-headers row">
                  <div className="col-sm-12 mt-2">
                    <span>{"Are you sure you want to delete this user?"}</span>
                  </div>
                  <div className="modal-actions col-sm-12">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleUserListDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              }
              onCancel={() => setIsModalOpen(false)}
              open={isModalOpen}
              footer={null}
            ></Modal>
            <DataTable
              columns={columns}
              data={registered_user_filtered_data}
              pagination
              className="table-style"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisteredUser;
