import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import DLogo from '../assets/images/d-logo.png';
import LogoWhite from '../assets/images/logo-1-white.png';
import DashboardImg from '../assets/images/dashboard.svg';
import ContentManagement from '../assets/images/content-management.svg';
import CarbonSettings from '../assets/images/carbon_settings.svg';
import Userquery from '../assets/images/userquery.svg';
import RegisteredUser from '../assets/images/registered.svg';
import ProductAndServices from '../assets/images/product&services.svg';
import UserUpload from '../assets/images/useruploads.svg';
import Appointments from '../assets/images/appointments-new.svg';
import Settings from '../assets/images/settings.svg';

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const [openMenus, setOpenMenus] = useState({}); // State to track open menus

  const toggleAccordion = (menu) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu], // Toggle only the clicked menu
    }));
  };
  
  const isActive = (path) => location.pathname === path;

    return (
        // <aside>
             <div className={`sidebar ${isSidebarOpen ? '' : 'close'}`}>
      <div className="logo-details py-3">
        <span className="d-logo"
          >
              <img src={DLogo} className="img-fluid" alt="" style={{width: '40px'}} />
        </span>
        <span className="full-logo"
          >
          <a href="./dashboard.html">
          <img src={LogoWhite} alt="project Logo" className="" />
        </a>
        </span>
      </div>
      <ul className="nav-links sidebar-accordion accordion" id="accordionExample">
        <li className={isActive('/dashboard') ? 'active' : ''}>
        <Link to="/dashboard">
          <div className="sidebar-icon">
            <img src={DashboardImg} alt="" />
          </div>
          <span className="link_name">DASHBOARD</span>
        </Link>
          <ul className="sub-menu close blank">
            <li><a className="link_name" href="/dashboard">DASHBOARD</a></li>
          </ul>
        </li>

        {/* <li className={isActive('/content-management') ? 'active' : ''}>
        <Link to="/content-management">
          <div className="sidebar-icon">
            <img src={ContentManagement} alt="" />
          </div>
          <span className="link_name">CONTENT MANAGEMENT</span>
        </Link>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="./content-management">CONTENT MANAGEMENT</a>
            </li>
          </ul>
        </li> */}
         <li className={isActive("/content-management") ? "active" : ""}>
      {/* Main Content Management Link */}
      <div className="sidebar-item"  onClick={() => toggleAccordion("contentManagement")} style={{ cursor: "pointer" }}>
        <div className="sidebar-icon">
          <img src={ContentManagement} alt="Content Management" style={{width: '22px'}}/>
        </div>
        <span className="link_name">CONTENT MANAGEMENT</span>
      </div>

      {/* Submenu Accordion */}
      <ul className={`sub-menu ${openMenus["contentManagement"] ? "open" : ""}`}
        style={{ display: openMenus["contentManagement"] ? "block" : "none" }}>
        <li className={isActive("/content-management") ? "active" : ""}>
          <Link to="/content-management" className="link_name">
            Question Management
          </Link>
        </li>
        <li className={isActive("/configuration") ? "active" : ""}>
          <Link to="/configuration" className="link_name">
            Configuration
          </Link>
        </li>
      </ul>
    </li>
        <li className={isActive('/user-queries') ? 'active' : ''}>
        <Link to="/user-queries">
          <div className="sidebar-icon">
            <img src={Userquery} alt="" />
          </div>
          <span className="link_name">USER QUERIES</span>
        </Link>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="./user-queries">USER QUERIES</a></li>
          </ul>
        </li>
        <li className={isActive('/registered-user') ? 'active' : ''}>
        <Link to="/registered-user">
          <div className="sidebar-icon">
            <img src={RegisteredUser} alt="" />
          </div>
          <span className="link_name">REGISTERED USERS</span>
        </Link>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="./registered-user">REGISTERED USERS</a></li>
          </ul>
        </li>

        <li className={isActive('/product-and-services') ? 'active' : ''}>
        <Link to="/product-and-services">
          <div className="sidebar-icon">
            <img src={ProductAndServices} alt="" />
          </div>
          <span className="link_name">PRODUCTS & SERVICES</span>
        </Link>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="./product-and-services">PRODUCTS & SERVICES</a></li>
          </ul>
        </li>

        

        <li className={isActive('/user-uploads') ? 'active' : ''}>
        <Link to="/user-uploads">
          <div className="sidebar-icon">
            <img src={UserUpload} alt="" />
          </div>
          <span className="link_name">USER UPLOADS</span>
        </Link>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="./user-uploads">USER UPLOADS</a></li>
          </ul>
        </li>

        
        <li className={isActive('/appointments') ? 'active' : ''}>
        <Link to="/appointments">
          <div className="sidebar-icon">
            <img src={Appointments} alt="" />
          </div>
          <span className="link_name">APPOINTMENTS</span>
        </Link>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="./appointments">APPOINTMENTS</a></li>
          </ul>
        </li>

        <li className={isActive("/template-settings") ? "active" : ""}>
      {/* Main Content Management Link */}
      <div className="sidebar-item"  onClick={() => toggleAccordion("settings")}
        style={{ cursor: "pointer" }}>
        <div className="sidebar-icon">
          <img src={Settings} alt="Content Management" style={{width: '22px'}}/>
        </div>
        <span className="link_name">SETTINGS</span>
      </div>

      {/* Submenu Accordion */}
      <ul  className={`sub-menu ${openMenus["settings"] ? "open" : ""}`}
        style={{ display: openMenus["settings"] ? "block" : "none" }}>
        <li className={isActive("/template-settings") ? "active" : ""}>
          <Link to="/template-settings" className="link_name">
            Template Settings
          </Link>
        </li>
        <li className={isActive("/dashboard-settings") ? "active" : ""}>
          <Link to="/dashboard-settings" className="link_name">
           Dashboard Settings
          </Link>
        </li>
      </ul>
    </li>
      </ul>
    </div>
        // </aside>
    );
}

export default Sidebar;
