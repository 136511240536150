import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import UploadImg from "../assets/images/upload.svg";
import ExcelImg from "../assets/images/excel.png";
import Close from "../assets/images/close.png";

const ChatView = () => {
  // const { name } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const [file, setFile] = useState(null); // State to hold the selected file

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFile(null); // Reset the file state
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />
        <div className="project-task" style={{height: '92vh'}}>
          <div className="inside-box p-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Configuration</h5>
              <button className="btn btn-primary">Train</button>
            </div>
            <div className="row">
            <div className="col-lg-10 m-auto">
              {/* File Input */}
              <div className="file-input">
                <input
                  type="file"
                  name="file-input"
                  id="file-input"
                  className="file-input__input"
                  accept=".xls,.xlsx" // Restrict to Excel files
                  onChange={handleFileChange} // Trigger file change
                />
                <label className="file-input__label" htmlFor="file-input">
                  <img src={UploadImg} alt="" className="file-img mb-3" />
                  <span>
                    Drag and Drop file here or{" "}
                    <span style={{ textDecoration: "underline", fontWeight: 600 }}>
                      choose file
                    </span>
                  </span>
                </label>
              </div>

              {/* File Information */}
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p style={{ color: "#8A94A5", fontSize: "16px" }}>
                  Supported formats: XLS, XLSX
                </p>
                <p style={{ color: "#8A94A5", fontSize: "16px" }}>
                  Maximum size: 25MB
                </p>
              </div>

              {/* Selected File Details */}
              {file && (
                <div className="upload-section d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={ExcelImg}
                      alt="Excel"
                      style={{ width: "50px" }}
                    />
                    <div className="mt-0 ms-3">
                      <h6 className="mb-0">{file.name}</h6>
                      <p className="mb-0" style={{ color: "#B5BAC5" }}>
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </p>
                    </div>
                  </div>
                  <div>
                    <img
                      src={Close}
                      alt="Remove File"
                      style={{ width: "13px", cursor: "pointer" }}
                      onClick={handleRemoveFile} // Handle file removal
                    />
                  </div>
                </div>
              )}
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChatView;
