import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// import "./assets/css/main.css";
import Dashboard from "./pages/Dashboard";
import ChatView from "./pages/ChatView";
import RegisteredUser from "./pages/RegisteredUser";
import ContentManagement from "./pages/ContentManagement";
import UserQueries from "./pages/UserQueries";
import ProductAndServices from "./pages/ProductAndServices";
import Login from "./pages/Login";
import Useruploads from "./pages/Useruploads";
import Configuration from "./pages/Configuration";
import ProtectedRoute from "./lib/privateRoute";
import Appointments from "./pages/Appointments";
import DashboardSettings from "./pages/DashboardSettings";
import TemplateSettings from "./pages/TemplateSettings";

function App() {
  useEffect(() => {
    const handleTabClose = () => {
      const apiUrl = `${process.env.REACT_APP_API_KEY}end_session`; // Replace with your actual API

      const sessionId = localStorage.getItem("chatSession");

      if (!sessionId) return; // Exit if no session_id exists

      // Prepare data to send
      const data = JSON.stringify({ session_id: sessionId });

      fetch(apiUrl, {
        method: "POST",
        body: data,
        headers: { "Content-Type": "application/json" },
        keepalive: true, // Ensures request completes even if tab is closed
      })
        .then((response) => {
          console.log("Fallback fetch response:", response);
          localStorage.removeItem("chatSession");
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    };

    // Attach event listener for tab close
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      // Clean up event listener
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/dashboard" element={ <Dashboard/> } /> */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbot/:name"
          element={
            <ProtectedRoute>
              <ChatView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/content-management"
          element={
            <ProtectedRoute>
              <ContentManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-queries"
          element={
            <ProtectedRoute>
              <UserQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registered-user"
          element={
            <ProtectedRoute>
              <RegisteredUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-and-services"
          element={
            <ProtectedRoute>
              <ProductAndServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-uploads"
          element={
            <ProtectedRoute>
              <Useruploads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Appointments"
          element={
            <ProtectedRoute>
              <Appointments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/configuration"
          element={
            <ProtectedRoute>
              <Configuration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template-settings"
          element={
            <ProtectedRoute>
              <TemplateSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-settings"
          element={
            <ProtectedRoute>
              <DashboardSettings />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
