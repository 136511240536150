import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DataTable from "react-data-table-component";
import Plus from "../assets/images/plus.svg";
import DeleteImg from "../assets/images/delete.png";
import EditImg from "../assets/images/edit.png";
import {
  Modal,
  Button,
  Form,
  Input,
  Menu,
  Dropdown,
  Collapse,
  Switch,
  Select,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { get, patch, post } from "../lib/HttpServices";
import { Cell } from "recharts";
import PreviewTemplate from "../components/PreviewTemplate";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";

// Helper to check if a type is a selection type.
const isSelectionType = (type) =>
  ["RadioButtonsGroup", "CheckboxGroup", "Dropdown"].includes(type);

const isTextAnswerType = (type) =>
  ["TextInput", "TextArea", "DatePicker"].includes(type);

const isOptInType = (type) => ["Opt-in"].includes(type);
const isShortAnswerType = (type) => ["TextInput"].includes(type);

const TemplateSettings = () => {
  const [form] = Form.useForm();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openResponsive, setOpenResponsive] = useState(false);
  const [panels, setPanels] = useState([]);
  // State for selection-type panels only.
  const [selectOptions, setSelectOptions] = useState({});
  // Current selected type from the dropdown.
  const [selectedType, setSelectedType] = useState("");

  const toggleSidebar = () => setIsSidebarOpen((prevState) => !prevState);
  const showModal = () => {
    form.resetFields();
    setIsEditing(false);
    setTemplateName("");
    setMode("Add");
    setOpenResponsive(true);
    setDescriptionValues({});
    setIsEnabled(false);
    setPanels([]);
    setFooterData("");
  };
  const showEditModal = (name) => {
    setIsEditing(true);
    setMode("Edit");
    // setTemplateName(name);
    setOpenResponsive(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setTemplateName("");
    form.setFieldsValue({ name: "" });
    setPanels([]);
    setPanelValues({});
    setRequiredFields({});
    setSelectOptionsValues({});
    setFooterData("");
    setErrors({});
    setOpenResponsive(false); // ✅ Close modal
  };
  const [templateList, setTemplateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [editedTemplateId, setEditedTemplateID] = useState("");
  const [isSaveConfirmModalOpen, setIsSaveConfirmModalOpen] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [panelValues, setPanelValues] = useState({});
  const [instructionValues, setInstructionValues] = useState({});
  const [descriptionValues, setDescriptionValues] = useState({});
  const [selectOptionsValues, setSelectOptionsValues] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [footerData, setFooterData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [mode, setMode] = useState("Add");
  const [modelMenuItems, setModelMenuItems] = useState({
    TextHeading: "Large Heading",
    TextSubheading: "Small Heading",
    TextCaption: "Caption",
    TextBody: "Body",
    TextInput: "Short Answer",
    TextArea: "Paragraph",
    DatePicker: "Date Picker",
    RadioButtonsGroup: "Single Choice",
    CheckboxGroup: "Multiple Choice",
    Dropdown: "Dropdown",
  });
  const [isEnabled, setIsEnabled] = useState(false);
  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const newErrors = {};

    //Template name validation
    if (!templateName.trim()) {
      newErrors.templateName = "Template name is required.";
    }

    if (!footerData.trim()) {
      newErrors.footerData = "Button name is required.";
    } else if (footerData.trim().length > 35) {
      newErrors.footerData = "Button cannot exceed 35 characters.";
    }

    panels.forEach((panel) => {
      const panelLabel = panelValues[panel.key]?.label?.trim();
      const panelDescription = descriptionValues[panel.key]?.trim() || "";
      const panelInstruction = instructionValues[panel.key]?.trim() || "";

      if (!panelLabel) {
        newErrors[`${panel.key}-label`] = "Label is required.";
      } else if (panelLabel.length > 20) {
        newErrors[`${panel.key}-label`] = "Label cannot exceed 20 characters";
      }

      // if (
      //   isTextAnswerType(panel.label) &&
      //   panelLabel &&
      //   panelLabel.length > 20
      // ) {
      //   newErrors[`${panel.key}-label`] = "Label cannot exceed 20 characters";
      // }

      // Check for Selection types (Description must not exceed 300 characters)
      if (
        (panel.label === "CheckboxGroup" ||
          panel.label === "RadioButtonsGroup") &&
        panelDescription.length > 300
      ) {
        newErrors[`${panel.key}-description`] =
          "Description cannot exceed 300 characters.";
      }

       // Instruction Validation (must not exceed 80 characters)
    if (panelInstruction.length > 80) {
      newErrors[`${panel.key}-instruction`] =
        "Instruction cannot exceed 80 characters.";
    }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ***************Edit Form Change
  useEffect(() => {
    if (openResponsive && mode === "Edit" && editedTemplateId) {
      fetchTemplateById();
    }
  }, [openResponsive, editedTemplateId]);

  // ****************

  useEffect(() => {
    console.log(panels, "Panels");
  }, [panels]);

  useEffect(() => {
    if (mode === "Add") {
      setTemplateName("");
      form.setFieldsValue({ name: "" });
      setPanels([]);
      setPanelValues({});
      setRequiredFields({});
      setSelectOptionsValues({});
      setFooterData("");
    }
  }, [mode]);

  const fetchTemplateList = () => {
    get(`template/list`)
      .then((res) => {
        if (res.data.templates) {
          const formattedData = res.data.templates.map((template) => ({
            id: template.id,
            templatename: template.name,
            status: template.status,
            whatsapp_approval_status: template.flow_status?.trim()
              ? template.flow_status
              : "--",
          }));
          setTemplateList(formattedData);
        }
      })
      .catch((err) => console.error("Error fetching templates:", err));
  };

  const rebuildFormState = (apiData, name) => {
    if (!apiData || apiData.length === 0) return;

    const formChildren = apiData[0]?.children || [];

    const newPanels = [];
    const newPanelValues = {};
    const newRequiredFields = {};
    const newSelectOptionsValues = {};
    const newInstruction = {};
    const newDescriptionFields = {};

    formChildren.forEach((item) => {
      const panelKey = item.name || `panel-${newPanels.length}`; // ✅ Use item.name for uniqueness

      if (
        ["TextHeading", "TextSubheading", "TextCaption", "TextBody"].includes(
          item.type
        )
      ) {
        if (!newPanels.some((p) => p.label === item.type)) {
          // ✅ Avoid duplicates
          newPanels.push({ key: panelKey, label: item.type });
        }
        newPanelValues[panelKey] = { label: item.text };
      } else if (["TextInput", "TextArea", "DatePicker"].includes(item.type)) {
        newPanels.push({ key: panelKey, label: item.type });
        newPanelValues[panelKey] = { label: item.label };
        newRequiredFields[panelKey] = item.required || false;
        if (item["helper-text"] && item["helper-text"].trim() !== "") {
          newInstruction[panelKey] = item["helper-text"];
        }
      } else if (
        ["RadioButtonsGroup", "CheckboxGroup", "Dropdown"].includes(item.type)
      ) {
        newPanels.push({ key: panelKey, label: item.type });
        newPanelValues[panelKey] = { label: item.label };
        newRequiredFields[panelKey] = item.required || false;
        newDescriptionFields[panelKey] = item.description || "";
        newSelectOptionsValues[panelKey] = item["data-source"].map(
          (option) => option.title
        );
      } else if (item.type === "Footer") {
        setFooterData(item.label || "Save");
      }
    });

    // Update states
    setPanels(newPanels);
    setPanelValues(newPanelValues);
    setRequiredFields(newRequiredFields);
    setSelectOptionsValues(newSelectOptionsValues);
    setInstructionValues(newInstruction);
    setDescriptionValues(newDescriptionFields);
  };

  const fetchTemplateById = () => {
    get(`template/${editedTemplateId}`)
      .then((res) => {
        if (res.data.template) {
          const data = res.data.template;
          console.log(data.name, "Name");

          console.log(
            data.json_structure.screens[0].layout.children,
            "Edit Data"
          );
          setTemplateName(data.name);
          form.setFieldsValue({ name: data.name });

          rebuildFormState(
            data.json_structure.screens[0].layout.children,
            data.name
          );

          setIsEnabled(data.status === "Active"); 
          // setTemplateList(formattedData);
        }
      })
      .catch((err) => console.error("Error fetching templates:", err));
  };

  useEffect(() => {
    if (editedTemplateId !== "" && mode === "Edit") {
      fetchTemplateById();
    }
  }, [editedTemplateId]);

  useEffect(() => {
    fetchTemplateList();
  }, []);

  useEffect(() => {
    setActiveKeys(panels.map((panel) => panel.key));
  }, [panels]);
  // When a menu item is clicked, update the selected type.
  const handleMenuClick = ({ key }) => {
    setSelectedType(key);
  };

  // When clicking "Add Fields", add a new panel with the current selected type.
  const addField = () => {
    const newPanelKey = `panel-${panels.length + 1}`;
    setPanels([...panels, { key: newPanelKey, label: selectedType }]);
    // Only initialize options for selection type panels.
    if (isSelectionType(selectedType)) {
      setSelectOptions({ ...selectOptions, [newPanelKey]: [] });
    }
    setSelectedType("");
  };

  const handleInputChange = (panelKey, field, value) => {
    setPanelValues((prevValues) => ({
      ...prevValues,
      [panelKey]: {
        ...prevValues[panelKey],
        [field]: value,
      },
    }));
    // Remove the error if the user starts typing
    if (field === "label" && value.trim()) {
      setErrors((prevErrors) => {
        const { [`${panelKey}-label`]: _, ...newErrors } = prevErrors;
        return newErrors;
      });
    }
  };

  const handleInstructionChange = (panelKey, value) => {
    setInstructionValues((prev) => ({
      ...prev,
      [panelKey]: value,
    }));
    // Remove error if instruction length is valid
    if (value.length <= 80) {
      setErrors((prevErrors) => {
        const { [`${panelKey}-instruction`]: _, ...newErrors } = prevErrors;
        return newErrors;
      });
    }
  };

  const handleDescriptionChange = (panelKey, value) => {
    setDescriptionValues((prev) => ({
      ...prev,
      [panelKey]: value,
    }));
    // Remove error message if user corrects the input
    if (value.length <= 300) {
      setErrors((prevErrors) => {
        const { [`${panelKey}-description`]: _, ...newErrors } = prevErrors;
        return newErrors;
      });
    }
  };

  const handleSelectChange = (panelKey, value) => {
    setPanelValues((prevValues) => ({
      ...prevValues,
      [panelKey]: {
        ...prevValues[panelKey],
        inputType: value, // Store selected value
      },
    }));
  };

  const handleToggleChange = (panelKey, checked) => {
    setRequiredFields((prev) => ({
      ...prev,
      [panelKey]: checked, // Store toggle value (true/false)
    }));
  };

  // Function to handle option input changes
  const handleOptionInputChange = (panelKey, index, value) => {
    setSelectOptionsValues((prev) => ({
      ...prev,
      [panelKey]:
        prev[panelKey]?.map((opt, i) => (i === index ? value : opt)) || [],
    }));
  };

  // Function to add an option dynamically
  const addOptionSelect = (panelKey) => {
    setSelectOptionsValues((prev) => ({
      ...prev,
      [panelKey]: [...(prev[panelKey] || []), ``],
    }));
  };

  // Function to remove an option
  const removeOptionSelect = (panelKey, index) => {
    setSelectOptionsValues((prev) => ({
      ...prev,
      [panelKey]: prev[panelKey]?.filter((_, i) => i !== index) || [],
    }));
  };

  const handleTemplateDelete = (row) => {
    setSelectedTemplateId(row.id);
    setIsModalOpen(true);
  };

  const handleTemplateListDelete = async () => {
    if (!selectedTemplateId) return; // Ensure an ID is selected

    try {
      await post(`template/delete/${selectedTemplateId}`);
      setIsModalOpen(false);
      fetchTemplateList();
      toast.success("Template deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error deleting template:", error);
      toast.error("Failed to delete template!", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  // Edited this function for add new template
  async function handleSaveConfirm() {
    try {
      const formStructure = generateFormStructure();
      const payload = {
        name: templateName,
        form_structure: {
          screens: [
            {
              data: {},
              id: "RECOMMEND",
              layout: {
                children: formStructure,
                type: "SingleColumnLayout",
              },
              terminal: true,
              title: templateName,
            },
          ],
          version: "6.3",
        },
        is_enabled: isEnabled,
      };
      let response;
      if (mode === "Add") {
        response = await post("template/create", payload);
        toast.success("Template added successfully!");
      } else if (mode === "Edit") {
        response = await patch(`template/edit/${editedTemplateId}`, payload);
        if (response) toast.success("Template updated successfully!");
      }

      setOpenResponsive(false); // ✅ Close main modal
      setIsSaveConfirmModalOpen(false);
      // Fetch updated list
      fetchTemplateList();
    } catch (error) {
      console.error("Error saving template:", error);

      if (error.response?.status === 500) {
        toast.error(error.response.data.message);
        fetchTemplateList();
        setIsSaveConfirmModalOpen(false);
        setOpenResponsive(false);
      } else {
        toast.error(error.response?.data?.message || "Something went wrong!");
      }
    }
  }

  // // For selection panels.
  // const addOptionSelect = (panelKey) => {
  //   setSelectOptions({
  //     ...selectOptions,
  //     [panelKey]: [...(selectOptions[panelKey] || []), ""],
  //   });
  // };

  // const removeOptionSelect = (panelKey, index) => {
  //   setSelectOptions({
  //     ...selectOptions,
  //     [panelKey]: selectOptions[panelKey].filter((_, i) => i !== index),
  //   });
  // };

  const columns = [
    {
      name: "Template Name",
      selector: (row) => row.templatename,
      sortable: true,
    },
    {
      name: "Status",
      center: true,
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span
          className={
            row.status === "Active"
              ? "status-active"
              : row.status === "Inactive"
              ? "status-inactive"
              : "status-default"
          }
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "Whatsapp Approval Status",
      selector: (row) => row.whatsapp_approval_status || "--",
      sortable: true,
      cell: (row) => (
        <span
          className={
            row.whatsapp_approval_status === "PUBLISHED"
              ? "status-approved"
              : row.whatsapp_approval_status === "REJECTED"
              ? "status-rejected"
              : row.whatsapp_approval_status === "DRAFT"
              ? "status-pending"
              : "status-default"
          }
        >
          {row.whatsapp_approval_status}
        </span>
      ),
    },
    {
      name: "Actions",
      center: true,
      selector: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="btn custom-btn-sec no-border no-padding"
            onClick={() => {
              setEditedTemplateID(row.id);
              showEditModal("Existing Template Name");
            }}
          >
            <img src={EditImg} alt="" />
          </button>
          <div className="border-right-line-table mx-4"></div>
          <button
            className="btn custom-btn-sec no-border no-padding"
            onClick={() => handleTemplateDelete(row)}
          >
            <img src={DeleteImg} alt="" />
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.SubMenu key="text" title="Text">
        <Menu.Item key="TextHeading">Large Heading</Menu.Item>
        <Menu.Item key="TextSubheading">Small Heading</Menu.Item>
        <Menu.Item key="TextCaption">Caption</Menu.Item>
        <Menu.Item key="TextBody">Body</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="textAnswer" title="Text Answer">
        <Menu.Item key="TextInput">Short Answer</Menu.Item>
        <Menu.Item key="TextArea">Paragraph</Menu.Item>
        <Menu.Item key="DatePicker">Date picker</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="selection" title="Selection">
        <Menu.Item key="RadioButtonsGroup">Single Choice</Menu.Item>
        <Menu.Item key="CheckboxGroup">Multiple Choice</Menu.Item>
        <Menu.Item key="Dropdown">Dropdown</Menu.Item>
        {/* <Menu.Item key="Opt-in">Opt-in</Menu.Item> */}
      </Menu.SubMenu>
    </Menu>
  );
  const removePanel = (panelKey) => {
    setPanels(panels.filter((panel) => panel.key !== panelKey));
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  // const handleSaveConfirm = () => {
  //   console.log("Template Saved!");
  //   setIsSaveConfirmModalOpen(false);
  // };

  const generateFormStructure = () => {
    console.log("panelValues[panel.key]::", descriptionValues);
    const formFields = panels.map((panel) => {
      const panelData = panelValues[panel.key] || {}; // Get values from state

      console.log("panelData", panelData);

      let field = {
        label: panelData.label || panel.label, // Use captured label
        name: `${panel.label.replace(/\s+/g, "_")}_${Math.random()
          .toString(36)
          .substring(7)}`,
        type: panel.label,
        required: requiredFields[panel.key] || false, // Include toggle state
      };

      if (
        ["TextHeading", "TextSubheading", "TextCaption", "TextBody"].includes(
          panel.label
        )
      ) {
        return {
          text: panelData.label, // Ensure 'text' property is included
          type: panel.label, // Type remains the same
        };
      }

      if (isSelectionType(panel.label)) {
        field = {
          ...field,
          type: panel.label,
          "data-source": (selectOptionsValues[panel.key] || []).map(
            (opt, index) => ({
              id: `${index}_${opt}`,
              title: opt,
            })
          ),
        };
        if (panel.label !== "Dropdown" && descriptionValues[panel.key]?.trim()) {
          field["description"] = descriptionValues[panel.key];
        }
        console.log("field Value::", field);
      } else if (isShortAnswerType(panel.label)) {
        field = { ...field, type: "TextInput", "input-type": "text" };
        if (instructionValues[panel.key]?.trim()) {
          field["helper-text"] = instructionValues[panel.key];
        }
      } else if (panel.label === "TextArea") {
        field = {
          ...field,
          type: "TextArea",
        };
        if (instructionValues[panel.key]?.trim()) {
          field["helper-text"] = instructionValues[panel.key];
        }
      } else if (panel.label.toLowerCase().includes("date")) {
        field = { ...field, type: "DatePicker" };
      }

      return field;
    });

    // ✅ Append Footer Object at the end
    formFields.push({
      label: footerData,
      "on-click-action": {
        name: "complete",
        payload: formFields.reduce((acc, field, index) => {
          if (field?.name) {
            acc[`screen_0_${field.name}_${index}`] = `\${form.${field.name}}`;
          }
          return acc;
        }, {}),
      },
      type: "Footer",
    });

    return [{ children: formFields, name: "flow_path", type: "Form" }];
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />
        <div className="project-task">
          <div className="inside-box p-4">
            <div className="d-flex align-items-center justify-content-end mb-4">
              <button
                className="btn-primary btn"
                onClick={showModal}
                style={{ padding: "8px 23px" }}
              >
                <img
                  src={Plus}
                  alt="Plus"
                  style={{ width: "15px", marginRight: "15px" }}
                />
                Add New Template
              </button>
            </div>
            <DataTable
              columns={columns}
              data={templateList}
              pagination
              className="table-style"
            />

            <Modal
              title={
                <div className="modal-headers row">
                  <div className="col-sm-12 mt-2">
                    <span>{"Are you sure you want to delete"}</span>
                  </div>
                  <div className="modal-actions col-sm-12">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleTemplateListDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              }
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              footer={null}
            ></Modal>
          </div>
        </div>
      </section>
      <Modal
        title={isEditing ? "Edit Template" : "Add New Template"}
        centered
        open={openResponsive}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <hr />
        <Form name="validateOnly" layout="vertical" autoComplete="off">
          {/* <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true }]}
          >
            <Input
              onChange={(e) => {
                setTemplateName(e.target.value);
                form.setFieldsValue({ name: e.target.value }); 
              }}
            />
          </Form.Item> */}
          <div className="mb-3">
            <label htmlFor="templateName" className="form-label">
              Template Name
            </label>
            <input
              type="text"
              className="form-control"
              value={templateName}
              required
              onChange={(e) => {
                setTemplateName(e.target.value);
                // Clear validation error while typing
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  templateName: "",
                }));
                form.setFieldsValue({ name: e.target.value }); // Keep form state in sync
              }}
            />
            {errors.templateName && (
              <span className="text-danger">{errors.templateName}</span>
            )}
          </div>
          <div className="d-flex align-items-center">
            <Form.Item label="Type">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="type-btn py-4"
              >
                {/* <Button className="d-flex align-items-center justify-content-between">
                  + Add content <DownOutlined />
                </Button> */}
                <Button className="d-flex align-items-center justify-content-between">
                  {selectedType
                    ? modelMenuItems[selectedType]
                    : "+ Add content"}{" "}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Form.Item>
            {selectedType && (
              <Button
                type="primary"
                onClick={addField}
                className="ms-4 mt-2"
                style={{ padding: "23px 20px" }}
              >
                <img
                  src={Plus}
                  alt="Plus"
                  style={{ width: "13px", marginRight: "10px" }}
                />{" "}
                Add Fields
              </Button>
            )}
          </div>
          <div className="output-section" style={{ marginTop: "20px" }}>
            <Collapse
              className="custom-collapse"
              activeKey={activeKeys}
              onChange={(keys) => setActiveKeys(keys)}
            >
              {panels
                .slice()
                .reverse()
                .map((panel) => (
                  <Collapse.Panel
                    key={panel.key}
                    header={
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span>{modelMenuItems[panel.label]}</span>
                        <button
                          className="btn custom-btn-sec no-border no-padding"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent collapse toggle on delete click
                            removePanel(panel.key);
                          }}
                        >
                          <img
                            src={DeleteImg}
                            alt="Delete"
                            style={{ width: "15px" }}
                          />
                        </button>
                      </div>
                    }
                  >
                    {isShortAnswerType(panel.label) && (
                      <div className="mb-3">
                        <Select
                          value={panelValues[panel.key]?.inputType || "Text"}
                          className="w-100"
                          onChange={(value) =>
                            handleSelectChange(panel.key, value)
                          }
                          options={[
                            {
                              value: "Text",
                              label: "Text",
                            },
                            {
                              value: "Password",
                              label: "Password",
                            },
                            {
                              value: "Email",
                              label: "Email",
                            },
                            {
                              value: "Number",
                              label: "Number",
                            },
                            {
                              value: "Passcode",
                              label: "Passcode",
                            },
                            {
                              value: "Phone",
                              label: "Phone",
                            },
                          ]}
                        />
                      </div>
                    )}
                    {/* Always show the label input */}
                    <input
                      type="text"
                      placeholder="Label"
                      // className="form-control"
                      className={`form-control ${
                        errors[`${panel.key}-label`] ? "is-invalid" : ""
                      }`}
                      value={panelValues[panel.key]?.label || ""}
                      onChange={(e) =>
                        handleInputChange(panel.key, "label", e.target.value)
                      }
                    />
                    {errors[`${panel.key}-label`] && (
                      <span className="text-danger">
                        {errors[`${panel.key}-label`]}
                      </span>
                    )}

                    {isSelectionType(panel.label) && (
                      <>
                        {panel.label !== "Dropdown" && (
                          <input
                            type="text"
                            placeholder="Description"
                            className="form-control mt-4"
                            value={descriptionValues[panel.key] || ""}
                            onChange={(e) =>
                              handleDescriptionChange(panel.key, e.target.value)
                            }
                          />
                        )}
                        {errors[`${panel.key}-description`] && (
                          <span className="text-danger">
                            {errors[`${panel.key}-description`]}
                          </span>
                        )}
                        <p className="my-3">Options</p>

                        {(selectOptionsValues[panel.key] || []).map(
                          (option, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center my-2"
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={option} // Set input value from state
                                onChange={(e) =>
                                  handleOptionInputChange(
                                    panel.key,
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                className="btn custom-btn-sec no-border no-padding ms-3"
                                onClick={() =>
                                  removeOptionSelect(panel.key, index)
                                }
                              >
                                <img
                                  src={DeleteImg}
                                  alt="Delete"
                                  style={{ width: "15px" }}
                                />
                              </button>
                            </div>
                          )
                        )}

                        <button
                          className="btn-primary btn mt-4"
                          onClick={() => addOptionSelect(panel.key)}
                        >
                          <img
                            src={Plus}
                            alt="Plus"
                            style={{ width: "13px", marginRight: "15px" }}
                          />
                          Add Option
                        </button>

                        <div className="mt-4 mb-2 text-end">
                          <label style={{ fontSize: "16px" }}>
                            Required
                            <Switch
                              checked={requiredFields[panel.key] || false} // Get value from state
                              onChange={(checked) =>
                                handleToggleChange(panel.key, checked)
                              } // Update state on change
                              className="ms-2"
                            />
                          </label>
                        </div>
                      </>
                    )}

                    {isTextAnswerType(panel.label) && (
                      <div className="mt-3">
                        <p>Instructions</p>
                        <input
                          type="text"
                          placeholder="Enter instructions"
                          // className="form-control"
                          className={`form-control ${
                            errors[`${panel.key}-instruction`]
                              ? "is-invalid"
                              : ""
                          }`}
                          value={instructionValues[panel.key] || ""}
                          onChange={(e) =>
                            handleInstructionChange(panel.key, e.target.value)
                          }
                        />
                        {errors[`${panel.key}-instruction`] && (
                          <span className="text-danger">
                            {errors[`${panel.key}-instruction`]}
                          </span>
                        )}

                        <div className="mt-4 mb-2 text-end">
                          <label style={{ fontSize: "16px" }}>
                            Required
                            <Switch
                              checked={requiredFields[panel.key] || false}
                              onChange={(checked) =>
                                handleToggleChange(panel.key, checked)
                              }
                              className="ms-2"
                            />
                          </label>
                        </div>
                      </div>
                    )}

                    {isOptInType(panel.label) && (
                      <>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Label"
                        ></textarea>
                        <div className="mt-4 text-end">
                          <Switch defaultChecked onChange={onChange} />{" "}
                          <label style={{ fontSize: "16px" }}>Required</label>
                        </div>
                      </>
                    )}
                  </Collapse.Panel>
                ))}
            </Collapse>
          </div>

          <div className="d-flex justify-content-between align-items-end mt-4">
            <div>
              <label>Button</label>
              <input
                className="form-control"
                type="text"
                value={footerData}
                onChange={(e) => {
                  setFooterData(e.target.value);

                  // Clear validation error while typing
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    footerData: "",
                  }));
                }}
              />
              {errors.footerData && (
                <span className="text-danger">{errors.footerData}</span>
              )}
            </div>

            {/* <button className="btn btn-primary">Save Template</button> */}
            <div className="">
              <label style={{ fontSize: "16px" }} className="me-2 mb-0">
                {isEnabled ? "Enabled" : "Disabled"}
              </label>
              <Switch
                checked={isEnabled}
                onChange={(checked) => setIsEnabled(checked)}
              />
              <Button
                type="primary"
                style={{ padding: "20px 25px" }}
                onClick={() => {
                  if (validateInputs()) {
                    setIsSaveConfirmModalOpen(true);
                  }
                }}
                className="ms-4"
              >
                Preview
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      <PreviewTemplate
        open={isSaveConfirmModalOpen}
        onClose={() => setIsSaveConfirmModalOpen(false)}
        onConfirm={handleSaveConfirm}
        templateName={templateName}
        generateFormStructure={generateFormStructure}
      />
    </div>
  );
};

export default TemplateSettings;
