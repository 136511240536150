import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import LeadsImg from "../assets/images/leads.svg";
import CarbonLocation from "../assets/images/carbon_location.svg";
import { get, patch } from "../lib/HttpServices";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import CloseIcon from "../assets/images/close.svg";
import { parse, format } from "date-fns";
import { Dropdown, Input, Menu, Space, Switch } from "antd";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import BarDiagram from "../components/BarDiagram";
import DataTable from "react-data-table-component";
import DocumentImg from "../assets/images/document.svg";
import ViewMenuImg from "../assets/images/view.png";
import DocumentTextImg from "../assets/images/document-text.svg";
import MessageTextImg from "../assets/images/message-chat.svg";
import DownloadMenuImg from "../assets/images/download.png";
import DocumentMenuImg from "../assets/images/drpdown-menu.svg";
import { useNavigate } from "react-router-dom";
import BxMenu from "../assets/images/bx-menu.svg";
import TableWithColumnFilter from "../components/TableWithFilterColumn";
import EditImg from "../assets/images/edit.png";
import tickImg from "../assets/images/tick.png";
import { toast } from "react-toastify";
import CountUser from "../assets/images/count-users.svg";

const ReactGridLayout = WidthProvider(RGL);

const DashboardSettings = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [fileType, setFileType] = useState("");
  const [patientContactNumber, setpatientContactNumber] = useState("");
  const [patientName, setpatientName] = useState("");
  const [selectedlisteofdoc_adduser, setselectedlisteofdoc_adduser] = useState(
    []
  );
  const [selectedlisteofdoc, setselectedlisteofdoc] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterCustomerData, setfilterCustomerData] = useState([]);
  const [widgets, setWidgets] = useState([
    { id: 1, layout: { x: 0, y: 0, w: 2, h: 2 } }, // Leads
    { id: 2, layout: { x: 2, y: 0, w: 2, h: 2 } }, // Location
  ]);
  const [headingLoc, setHeadingLoc] = useState("Locations");
  const [tempHeadingLoc, setTempHeadingLoc] = useState("Locations");
  const [headingLeads, setHeadingLeads] = useState("Leads");
  const [tempHeadingLeads, setTempHeadingLeads] = useState("Leads");
  const [headingUser, setHeadingUser] = useState("Users");
  const [tempHeadingUser, setTempHeadingUser] = useState("Users");
  const [isEditingLoc, setIsEditingLoc] = useState(false);
  const [isEditingLeads, setIsEditingLeads] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [registeredUsers,setRegisteredUsers] = useState(0);
  const [unRegisteredUsers,setUnRegisteredUsers] = useState(0);

  const widgetOptions = [
    { value: 1, label: "Lead" },
    { value: 2, label: "Location" },
    { value: 3, label: "Users" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    console.log(widgets, "Widgets");
  }, [widgets]);

  useEffect(() => {
    const fetchDashboardLayout = async () => {
      get(`dashboard/widgets`)
        .then((res) => {
          setWidgets(res.data.widgets);
          console.log("widgets response is", res.data.widgets);
          res.data.widgets.forEach((widget) => {
            if (widget.id === 1) setHeadingLeads(widget.name || "Leads");
            if (widget.id === 2) setHeadingLoc(widget.name || "Locations");
            if (widget.id === 3) setHeadingUser(widget.name || "Users");
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log("errr");
          }
        });
    };

    fetchDashboardLayout();
  }, []);


  useEffect(() => {
    const fetchUserCount = async() => {
      try{
        const response = await get(`user_count`);
        if (response.status){
          setRegisteredUsers(response.data.data.registered_users);
          setUnRegisteredUsers(response.data.data.unregistered_users);
        }
      } catch(error){
        console.error("Error fetching user count:",error);      
      }
    };
    fetchUserCount();
  },[]);


  const [leadgraphData] = useState([
    { count: 6, day: "26 Feb 2025" },
    { count: 3, day: "25 Feb 2025" },
    { count: 1, day: "20 Feb 2025" },
  ]);

  let LocationBarData = [
    { country: "SA", percentage: 3 },
    { country: "IN", percentage: 7 },
  ];

  let totalLeads = "6";

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const handleChange_adduser = (value) => {
    console.log("Updated selected documents:", value);
    setselectedlisteofdoc_adduser(value); // Directly update the state with the current value
  };

  const handleRequestDocument = (row) => {
    console.log("row::", row);

    setpatientName(row.name);
    setpatientContactNumber(row.mobile);
    // Ensure requested_documents is defined and is an array before mapping
    const defaultDocuments = Array.isArray(row.requested_documents)
      ? row.requested_documents.map((doc) => doc.document_name)
      : [];
    console.log("defaultDocuments-----", defaultDocuments);
    setselectedlisteofdoc(defaultDocuments);
    setIsModalOpen(true);
    console.log("defaultDocuments:-:", defaultDocuments);
  };

  const handleViewClick = (lead_id, name, date, location) => {
    // Navigate to the ChatView component
    navigate(`/chatbot/${lead_id}`, {
      state: { lead_id, name, date, location }, // Pass customer_id and name as state
    });
  };

  const handleLocEditClick = (event) => {
    event.stopPropagation();
    if (isEditingLoc) {
      setHeadingLoc(headingLoc.trim() ? headingLoc : tempHeadingLoc);
    } else {
      setTempHeadingLoc(headingLoc);
    }
    console.log("Test");
    setIsEditingLoc(!isEditingLoc);
  };

  const handleLeadsEditClick = (event) => {
    event.stopPropagation();
    if (isEditingLeads) {
      setHeadingLeads(headingLeads.trim() ? headingLeads : tempHeadingLeads);
    } else {
      setTempHeadingLeads(headingLeads);
    }
    setIsEditingLeads(!isEditingLeads);
  };

  const handleUserEditClick = (event) => {
    event.stopPropagation();
    if(isEditingUser){
      setHeadingUser(headingUser.trim() ? headingUser : tempHeadingUser);
    } else {
      setTempHeadingUser(headingUser);
    }
    setIsEditingUser(!isEditingUser);
  };


  const handleLocInputChange = (event) => {
    setHeadingLoc(event.target.value);
  };

  const handleLeadsInputChange = (event) => {
    setHeadingLeads(event.target.value);
  };

  const handleUserInputChange = (event) => {
    setHeadingUser(event.target.value);
  };

  const myNewTheme = {
    rows: {
      fontSize: "50px",
    },
  };
  const documentMenu = (row) => (
    <Menu>
      {Array.isArray(row.documents) && row.documents.length > 0 ? (
        row.documents.map((doc) => (
          <Menu.Item key={doc.file_id} className="doc-lits">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "180px",
                  marginRight: "10px",
                  background: "rgb(241, 244, 248)",
                  borderRadius: "50px",
                  padding: "8px 11px",
                }}
              >
                <img
                  src={DocumentImg}
                  alt=""
                  style={{ width: "14px", marginRight: "8px" }}
                />
                {doc.file_name}
              </span>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <div
                  style={{
                    background: "rgb(241, 244, 248)",
                    borderRadius: "50px",
                    // margin: '0px 10px',
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={ViewMenuImg}
                    alt="View"
                    style={{
                      width: "20px",
                      cursor: "pointer",
                      background: "rgb(241, 244, 248)",
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setModalOpen(true);
                      setImageSrc(doc.file_path);
                      setFileType(doc.file_type);
                    }}
                  />
                </div>
                <div
                  style={{
                    background: "rgb(241, 244, 248)",
                    borderRadius: "50px",
                    // margin: '0px 10px',
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={DownloadMenuImg}
                    alt="Download"
                    style={{ width: "18px", cursor: "pointer" }}
                    onClick={async () => {
                      try {
                        const response = await fetch(doc.file_path);
                        if (!response.ok)
                          throw new Error("Failed to download file");
                        const blob = await response.blob();
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = doc.file_name || "downloaded_file";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href);
                      } catch (error) {
                        console.error("Error downloading file:", error);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>No documents available</Menu.Item>
      )}
    </Menu>
  );
  const columns = [
    {
      name: "Date",
      selector: (row) => parse(row.date, "dd MMM yyyy, hh:mm a", new Date()), // Parse based on the date format
      // cell: (row) => <div>{format(parse(row.date, 'dd MMM yyyy, hh:mm a', new Date()), 'PPpp')}</div>, // Reformat if needed
      cell: (row) => <div>{row.date}</div>,
      sortable: true,
    },

    {
      name: <div>Mobile</div>,
      selector: (row, i) => row.mobile,
      // cell: (row) => <div>{row.lastName}</div>,
      sortable: true,
    },
    {
      name: <div>Location</div>,
      selector: (row, i) => row.location,
      sortable: true,
    },
    {
      name: <div>Name</div>,
      //  selector: "lastName",
      selector: (row, i) => row.name,
      // cell: (row) => <div>{row.lastName}</div>,
      sortable: true,
    },
    {
      name: "Documents",
      selector: (row) => row.documents,
      minWidth: "300px",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "60%",
          }}
        >
          <span style={{ minWidth: "100px" }}>
            {row.documents?.length || 0} Documents
          </span>
          <Dropdown
            overlay={documentMenu(row)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img
              src={DocumentMenuImg}
              alt="Menu"
              style={{ width: "25px", marginLeft: "15px", cursor: "pointer" }}
            />
          </Dropdown>
          <button
            className="btn custom-btn-sec"
            style={{ marginLeft: "15px", minWidth: "125px" }}
            onClick={() => handleRequestDocument(row)}
          >
            Request{" "}
            <img
              src={DocumentImg}
              alt="document"
              style={{ width: "12px" }}
              className="ms-2"
            />
          </button>
        </div>
      ),
    },
    {
      name: "Actions",
      // width: "4rem",
      center: true,
      selector: (row) => (
        <div className="d-flex align-items-center">
          <button className="btn custom-btn-sec no-border no-padding">
            <img src={DocumentTextImg} alt="" />
          </button>
          <div className="border-right-line-table"></div>
          <button
            className="btn custom-btn-sec no-border no-padding"
            onClick={() =>
              handleViewClick(row.mobile, row.name, row.date, row.location)
            }
          >
            <img src={MessageTextImg} alt="" />
          </button>
        </div>
      ),
    },
  ];

  const renderWidgetComponent = (id) => {
    switch (id) {
      case 1:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>

                {isEditingLeads ? (
                  <input
                    type="text"
                    value={headingLeads}
                    onChange={handleLeadsInputChange}
                    autofocus
                    className="ms-3 form-control"
                    style={{ width: "150px" }}
                  />
                ) : (
                  <h4 className="mb-0 ms-3">{headingLeads}</h4>
                )}

                <button
                  onClick={handleLeadsEditClick}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  className="ms-3"
                >
                  <img src={isEditingLeads ? tickImg : EditImg} alt="Edit" />
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setWidgets(widgets.filter((data) => data.id !== 1));
                  }}
                  style={{ background: "transparent", border: "0" }}
                >
                  <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1">
              <div className="d-flex align-items-center justify-content-start">
                <div className="invisible">
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>
                <div className="ms-3">
                  <h1>{totalLeads}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Total Leads
                  </p>
                </div>
              </div>

              <ResponsiveContainer width="60%" height={200}>
                <LineChart data={leadgraphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#524bd6"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={CarbonLocation} alt="" style={{ width: "35px" }} />
                </div>

                {isEditingLoc ? (
                  <Input
                    type="text"
                    value={headingLoc}
                    onChange={handleLocInputChange}
                    // onBlur={handleBlur}
                    autofocus
                    className="ms-3 form-control"
                    style={{ width: "150px" }}
                  />
                ) : (
                  <h4 className="mb-0 ms-3">{headingLoc}</h4>
                )}

                <button
                  onClick={handleLocEditClick}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  className="ms-3"
                >
                  <img src={isEditingLoc ? tickImg : EditImg} alt="Edit" />
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setWidgets(widgets.filter((data) => data.id !== 2));
                  }}
                  style={{ background: "transparent", border: "0" }}
                >
                  <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="d-flex align-items-center justify-content-start">
                <div className="invisible">
                  <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                </div>
                <div className="ms-3">
                  <h1>5</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Total Locations
                  </p>
                </div>
              </div>
              <BarDiagram data={LocationBarData} />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="inside-box">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-start">
                <div>
                  <img src={CountUser} alt="" style={{ width: "30px" }} />
                </div>

                {isEditingUser ? (
                  <Input
                    type="text"
                    value={headingUser}
                    onChange={handleUserInputChange}
                    // onBlur={handleBlur}
                    autofocus
                    className="ms-3 form-control"
                    style={{ width: "150px" }}
                  />
                ) : (
                  <h4 className="mb-0 ms-3">{headingUser}</h4>
                )}

                <button
                  onClick={handleUserEditClick}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  className="ms-3"
                >
                  <img src={isEditingUser ? tickImg : EditImg} alt="Edit" />
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setWidgets(widgets.filter((data) => data.id !== 3));
                  }}
                  style={{ background: "transparent", border: "0" }}
                >
                  <img src={CloseIcon} style={{ width: "25px" }} alt="Close" />
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 h-100">
              <div className="d-flex align-items-center justify-content-start mb-5">
                {/* <div className="invisible">
                    <img src={LeadsImg} alt="" style={{ width: "35px" }} />
                  </div> */}
                <div className="ms-3">
                  <h1>{registeredUsers}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Registered Users
                  </p>
                </div>
                <div className="ms-3">
                  <h1>{unRegisteredUsers}</h1>
                  <p className="mb-0" style={{ color: "#A4ABC8" }}>
                    Unregistered Users
                  </p>
                </div>
              </div>
              {/* <BarDiagram data={LocationBarData} /> */}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleLayoutChange = (newLayout) => {
    const updatedWidgets = widgets.map((widget) => {
      const updatedLayout = newLayout.find(
        (layout) => layout.i === widget.id.toString()
      );
      return { ...widget, layout: updatedLayout };
    });
    setWidgets(updatedWidgets);
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  const addWidgets = () => {
    if (selectedOptions.length === 0) return;

    const newWidgets = selectedOptions.map((option) => ({
      id: option.value,
      layout: { x: 0, y: Infinity, w: 1, h: 2 },
    }));

    setWidgets((prev) => [...prev, ...newWidgets]);
    setSelectedOptions([]);
  };
  const columnsdummy = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Documents",
      selector: (row) => row.documents,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ];

  const filterCustomerDatadummy = [
    {
      date: 1,
      mobile: "John Doe",
      location: "john.doe@example.com",
      name: "+1 123 456 7890",
      documents: "USA",
      actions: "USA",
    },
    {
      date: 1,
      mobile: "John Doe",
      location: "john.doe@example.com",
      name: "+1 123 456 7890",
      documents: "USA",
      actions: "USA",
    },
    {
      date: 1,
      mobile: "John Doe",
      location: "john.doe@example.com",
      name: "+1 123 456 7890",
      documents: "USA",
      actions: "USA",
    },
    {
      date: 1,
      mobile: "John Doe",
      location: "john.doe@example.com",
      name: "+1 123 456 7890",
      documents: "USA",
      actions: "USA",
    },
    {
      date: 1,
      mobile: "John Doe",
      location: "john.doe@example.com",
      name: "+1 123 456 7890",
      documents: "USA",
      actions: "USA",
    },
  ];
  const onChangeSwitch = (checked) => {
    console.log("Switch changed:", checked);
  };

  // Prevent dropdown from closing when clicking inside
  const handleMenuClick = (event) => {
    event.domEvent.stopPropagation(); // Correct way to stop event in Ant Design
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0">
        <Switch defaultChecked onChange={onChangeSwitch} />
        <label className="ms-3"> Date </label>
      </Menu.Item>
      <Menu.Item key="1">
        <Switch defaultChecked onChange={onChangeSwitch} />
        <label className="ms-3"> Time </label>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />
        <div className="project-task">
          <Form>
            <div className="d-flex align-items-end justify-content-between my-4">
              <div
                className="d-flex align-items-end justify-content-start"
                style={{ gap: "15px" }}
              >
                <Form.Group>
                  <Select
                    isMulti
                    placeholder="Select Widget"
                    value={selectedOptions}
                    onChange={handleChange}
                    options={widgetOptions.filter(
                      (option) =>
                        !widgets.some((widget) => widget.id === option.value)
                    )}
                    isSearchable
                  />
                </Form.Group>
                <button
                  className="btn-primary btn"
                  onClick={(e) => {
                    e.preventDefault();
                    addWidgets();
                  }}
                >
                  Add Widget
                </button>
              </div>
              <div className="d-flex justify-content-end ">
                <button
                  className="btn-primary btn"
                  onClick={async (e) => {
                    e.preventDefault();
                    try {
                      const payload = widgets.map((widget) => ({
                        id: widget.id, // Include the widget id for identification
                        widget_name:
                          widget.id === 1
                            ? headingLeads
                          : widget.id === 2
                            ? headingLoc
                          :widget.id === 3
                            ? headingUser
                          : "",
                        layout: {
                          x: widget.layout.x,
                          y: widget.layout.y,
                          w: widget.layout.w,
                          h: widget.layout.h,
                        },
                      }));
                      await patch("dashboard/widgets/index", payload);

                      toast.success("Data saved successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                      // alert("Saved successfully");
                      // navigate("/client");
                    } catch (err) {
                      console.error(err);
                      toast.error("Failed to save data!", {
                        position: "top-right",
                        autoClose: 3000,
                      });
                    }
                  }}
                  style={{ padding: "8px 23px" }}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
          <ReactGridLayout
            className="layout"
            layout={widgets.map((w) => ({ ...w.layout, i: w.id.toString() }))}
            cols={4}
            rowHeight={250}
            width={1200}
            onLayoutChange={handleLayoutChange}
          >
            {widgets.map((widget) => (
              <div key={widget.id}>{renderWidgetComponent(widget.id)}</div>
            ))}
          </ReactGridLayout>

          <TableWithColumnFilter />

          {/* <div className="inside-box position-relative">
            <div className="table-custom">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a onClick={(e) => e.preventDefault()}>
                  <img src={BxMenu} alt="" style={{ width: "22px" }} />
                </a>
              </Dropdown>
            </div>
            <DataTable
              columns={columnsdummy}
              data={filterCustomerDatadummy}
              highlightOnHover
              customTheme={myNewTheme}
              pagination
              // selectableRows={true}
              // contextActions={contextActions}

              // fixedHeader
              // selectableRowsVisibleOnly={false}
              options={{
                disableSelectionOnClick: true,
                noHeader: true,
              }}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationComponentOptions={{
                rowsPerPageText: "Records per page:",
                rangeSeparatorText: "out of",
              }}
              className="table-style "
            />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default DashboardSettings;
